import React, { forwardRef } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { cn } from "~/utils/cn";

interface SubmitButtonProps {
  handleClick: any;
  posted: any;
  disabled: any;
  type: any;
  MarkdownEditorTypes: any;
}

const SubmitButton = forwardRef(
  (
    {
      handleClick,
      posted,
      encrypted,
      disabled,
      type,
      MarkdownEditorTypes
    }: SubmitButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        onClick={handleClick}
        about={"CTRL + ENTER"}
        title={"CTRL + ENTER"}
        className={cn(
          "flex items-center justify-center px-5 py-2 gap-x-1.5 text-sm font-semibold text-pri rounded-3xl bg-acc hover:bg-acc-hov disabled:cursor-not-allowed disabled:opacity-75 transition-all duration-300",
          {
            "bg-green-600 disabled:bg-green-500 disabled:opacity-100 duration-30":
              posted,
            "bg-c-subscriptions hover:bg-c-subscriptions/90":
              encrypted && !posted
          }
        )}
        disabled={disabled}
      >
        {encrypted && !posted ? (
          <FontAwesomeIcon icon={faCrown} size="sm" className="-mt-px" />
        ) : null}
        {posted ? "Onchain" : MarkdownEditorTypes[type]}
      </button>
    );
  }
);

export default SubmitButton;

