export function DecryptLoader() {
  return (
    <div className="flex flex-col w-full gap-y-2 py-4 animate-pulse">
      <span className="flex w-full h-4 rounded-xl bg-gray-200 dark:bg-zinc-800" />
      <span className="flex w-1/2 h-4 rounded-xl bg-gray-200 dark:bg-zinc-800" />
      <span className="flex w-3/4 h-4 rounded-xl bg-gray-200 dark:bg-zinc-800" />
    </div>
  );
}

