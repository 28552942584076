import { faFolder, faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLoaderData } from "@remix-run/react";
import { Fragment, Suspense, lazy, useEffect, useMemo, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { cache } from "~/utils/cache";
import { HiveContent, PossibleHiveContent } from "~/utils/hive";
import { useParsedBody, useStrippedSlicedBody } from "~/utils/markdown";

import {
  faAdd,
  faBookmark as faBookmarkSolid,
  faBorderAll,
  faChevronLeft,
  faEllipsisH,
  faList,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import InfoHeader, {
  BackButton,
  InfoHeaderLabel
} from "~/components/InfoHeader";
import classNames from "classnames";
import { Bookmark, leocache, removeBookmark } from "~/utils/leocache";
import { isSSR } from "~/utils/ssr";
import { toast } from "react-toastify";
import BookmarksDropdown from "~/components/bookmarks/BookmarksDropdown";
import CreateFolderModal from "~/components/bookmarks/CreateFolder";
import FolderDropdown from "~/components/bookmarks/FolderDropdown";
import { SmallAvatar12 } from "~/components/format/SmallAvatar";
import Reputation from "~/components/format/Reputation";
import DisplayName from "~/components/format/DisplayName";
import AccountName from "~/components/format/AccountName";
import { TimeSincePublish } from "~/components/TimeSincePublish";
import { useAppStore } from "~/store";
import { getActiveAccount } from "~/session.server";
import { LoaderArgs, redirect } from "@remix-run/node";

const ProfileLink = lazy(() => import("~/components/ProfileLink"));

export const loader = async ({ request }: LoaderArgs) => {
  const activeAccount = await getActiveAccount(request);

  if (!activeAccount) {
    return redirect("/threads");
  }

  const bookmarks = await leocache.getAccountBookmarks(activeAccount, true);
  return { bookmarks };
};

/**
 * bookmarks components
 */
export default function Bookmarks() {
  const { bookmarks } = useLoaderData();

  const [activeAccount, premiumState, setBookmarks] = useAppStore(store => [
    store.account.activeAccount,
    store.account.premium,
    store.account.setBookmarks
  ]);

  const [display, setDisplay] = useState<"list" | "grid">(
    (!isSSR() &&
      (window.localStorage.getItem("bookmarks-view") as "list" | "grid")) ||
      "list"
  );
  const [focusedFolder, setFocusedFolder] = useState<string | null>(
    premiumState.is_premium ? null : ""
  );

  const [createFolderVisibility, setCreateFolderVisibility] = useState(false);

  useEffect(() => {
    setBookmarks(bookmarks);
  }, [bookmarks]);

  const changeDisplay = (display: "list" | "grid") => {
    if (isSSR()) return;

    window.localStorage.setItem("bookmarks-view", display);
    setDisplay(display);
  };

  const folderThreads = useMemo(() => {
    if (!bookmarks) return [];

    // merge all bookmarks
    if (focusedFolder === "") {
      const permlinks = bookmarks.reduce(
        (prev: Bookmark["permlinks"], cur: Bookmark) => [
          ...prev,
          ...cur.permlinks
        ],
        []
      );

      return permlinks
        .filter(
          (bookmark: string, index: number) =>
            permlinks.indexOf(bookmark) === index
        )
        .reverse();
    }

    return bookmarks.find(x => x.folder === focusedFolder)?.permlinks;
  }, [focusedFolder, bookmarks]);

  return (
    <div className="w-full pc:w-8/12 flex flex-col border border-pri dark:border-pri-d">
      <InfoHeader className="items-center">
        <BackButton />
        <InfoHeaderLabel>Bookmarks</InfoHeaderLabel>

        <div className="relative flex ml-auto mr-4">
          <BookmarksDropdown onFocusChange={() => {}}>
            <button className="relative flex items-center justify-center w-8 h-8 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150">
              <FontAwesomeIcon icon={faEllipsisH} fixedWidth />
            </button>
          </BookmarksDropdown>
        </div>
      </InfoHeader>

      {premiumState.is_premium && (
        <div
          className={classNames(
            "flex justify-between items-center w-full py-2 pr-5 border-b border-pri dark:border-pri-d",
            {
              "pl-5": focusedFolder,
              "pl-4": !focusedFolder
            }
          )}
        >
          <div className="flex items-center">
            {focusedFolder !== null ? (
              <button
                type="button"
                className="flex items-center gap-x-3 text-sm font-semibold hover:opacity-60 transition-opacity duration-150"
                onClick={() => setFocusedFolder(null)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span className="max-w-[300px] text-ellipsis whitespace-nowrap overflow-hidden">
                  {focusedFolder || "All"}
                </span>
              </button>
            ) : (
              <strong className="text-sm font-semibold">Folders</strong>
            )}
          </div>

          <div className="flex items-center gap-x-5 divide-x divide-pri dark:divide-pri-d">
            <button
              type="button"
              className="flex items-center py-2.5 px-4 gap-x-2 rounded-full border border-pri dark:border-pri-d text-sm font-semibold hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150"
              onClick={() => setCreateFolderVisibility(true)}
            >
              <FontAwesomeIcon icon={faAdd} size="sm" fixedWidth />
              Create Folder
            </button>

            <div className="flex items-center gap-x-3 pl-5">
              <button
                type="button"
                className={classNames(
                  "flex justify-center items-center w-8 h-8 transition-opacity duration-150",
                  {
                    "opacity-50 hover:opacity-100": display === "grid"
                  }
                )}
                onClick={() => changeDisplay("list")}
              >
                <FontAwesomeIcon icon={faList} fixedWidth />
              </button>

              <button
                type="button"
                className={classNames(
                  "flex justify-center items-center w-8 h-8 transition-opacity duration-150",
                  {
                    "opacity-50 hover:opacity-100": display === "list"
                  }
                )}
                onClick={() => changeDisplay("grid")}
              >
                <FontAwesomeIcon icon={faBorderAll} fixedWidth />
              </button>
            </div>
          </div>
        </div>
      )}

      <ClientOnly>
        {() =>
          bookmarks === null ? (
            <BookmarksLoader />
          ) : focusedFolder !== null ? (
            folderThreads && folderThreads.length > 0 ? (
              folderThreads.map((bookmark: string, index) => (
                <BookmarkedThread
                  key={index}
                  bookmark={bookmark}
                  folder={focusedFolder}
                />
              ))
            ) : (
              <div className="flex flex-col justify-center items-center w-full mt-10 gap-1.5 text-center">
                <h1 className="text-3xl font-bold">Waiting for bookmarks.</h1>
                <p className="text-sm font-medium text-pri/60 dark:text-pri-d/60">
                  You can see the bookmarks here that this folder includes.
                </p>
              </div>
            )
          ) : bookmarks.length > 0 ? (
            <div
              className={classNames("w-full", {
                "flex flex-col divide-y divide-pri/50 dark:divide-pri-d/50":
                  display === "list",
                "grid gap-[1px] pb-[1px] grid-cols-3 bg-[rgb(229_231_235_/_0.5)] dark:bg-[rgb(63_63_70_/_0.5)]":
                  display === "grid"
              })}
            >
              {bookmarks.map((bookmark, id) => (
                <Folder
                  key={id}
                  bookmarks={bookmarks}
                  folder={bookmark}
                  display={display}
                  setFocusedFolder={setFocusedFolder}
                />
              ))}

              {display === "grid" && bookmarks.length % 3 === 1 && (
                <Fragment>
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                </Fragment>
              )}

              {display === "grid" && bookmarks.length % 3 === 2 && (
                <Fragment>
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                </Fragment>
              )}

              {display === "grid" && bookmarks.length % 3 === 3 && (
                <Fragment>
                  <span className="flex flex-1 bg-pri dark:bg-pri-d" />
                </Fragment>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center w-full mt-10 gap-1.5 text-center">
              <h1 className="text-3xl font-bold">Waiting for bookmarks.</h1>
              <p className="text-sm font-medium text-pri/60 dark:text-pri-d/60">
                You can see the bookmarks here and organize them with creating
                folder.
              </p>
            </div>
          )
        }
      </ClientOnly>

      <CreateFolderModal
        visibility={createFolderVisibility}
        setVisibility={setCreateFolderVisibility}
      />
    </div>
  );
}

function Folder({
  bookmarks,
  folder,
  display,
  setFocusedFolder
}: {
  bookmarks: Bookmark[];
  folder: Bookmark;
  display: "list" | "grid";
  setFocusedFolder: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const special_folders = {
    favorites: "bg-[#ed2b4b] dark:bg-[#e31337]"
  };

  const handleFolderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.target?.hasAttribute("data-prevent-navigate")) {
      return;
    }

    setFocusedFolder(folder.folder);
  };

  return (
    <button
      type="button"
      className={classNames(
        "flex items-center py-3.5 px-5 bg-pri dark:bg-pri-d duration-150",
        {
          "w-full gap-x-3 hover:bg-pri-d/[0.05] dark:hover:bg-pri/[0.05]":
            display === "list",
          "flex-col justify-center py-5 hover:bg-pri/[0.95] dark:hover:bg-pri-d/[0.95]":
            display === "grid"
        }
      )}
      style={{ transitionProperty: "background-color, color" }}
      onClick={handleFolderClick}
    >
      <span
        className={classNames(
          "flex justify-center items-center shrink-0 w-12 h-12 rounded-full",
          ...Object.entries(special_folders).map(([key, value]) => ({
            ...(key.toLowerCase() === folder.folder.toLowerCase()
              ? { [value]: true }
              : {
                  "bg-gray-200 dark:bg-zinc-700": true
                })
          }))
        )}
      >
        <FontAwesomeIcon
          icon={
            folder.folder.toLowerCase() === "favorites"
              ? faHeart
              : folder.folder === ""
              ? faBorderAll
              : faFolder
          }
          size="lg"
          fixedWidth
        />
      </span>

      <span
        className={classNames(
          "text-base text-center font-semibold text-pri dark:text-pri-d",
          {
            "mt-2": display === "grid"
          }
        )}
      >
        <span className="line-clamp-2">{folder.folder || "All"}</span>
      </span>

      <div className="flex items-center ml-auto gap-x-1">
        <span
          className={classNames(
            "text-sm font-semibold text-pri/70 dark:text-pri-d/70",
            {
              "ml-auto pr-4": display === "list",
              "text-center": display === "grid"
            }
          )}
        >
          {folder.folder === ""
            ? bookmarks.reduce((prev, cur) => prev + cur.permlinks.length, 0)
            : folder.permlinks.length}
        </span>

        <FolderDropdown folder={folder}>
          <button
            data-prevent-navigate
            className="relative flex items-center justify-center w-8 h-8 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150"
          >
            <FontAwesomeIcon
              data-prevent-navigate
              icon={faEllipsisH}
              fixedWidth
            />
          </button>
        </FolderDropdown>
      </div>
    </button>
  );
}

function BookmarkedThread({
  bookmark,
  folder
}: {
  bookmark: string;
  folder: string;
}) {
  const [thread, setThread] = useState<PossibleHiveContent | null>(null);

  useEffect(() => {
    void (async function () {
      const perm = {
        author: bookmark.split("/")[0],
        permlink: bookmark.split("/")[1]
      };

      const post = await cache.getContent(perm);
      setThread(post);
    })();
  }, [bookmark]);

  if (!thread)
    return (
      <div className="flex justify-center items-center w-full py-5 px-5 min-h-[134px] border-b border-pri dark:border-pri-d">
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" fixedWidth />
      </div>
    );
  return <PostPreview post={thread} folder={folder} />;
}

function PostPreview({ post, folder }: { post: HiveContent; folder: string }) {
  const [{ activeAccount, keys }, isDarkMode] = useAppStore(store => [
    store.account,
    store.settings.dark
  ]);
  const { signature, publicKey, proxy } = keys;
  const [_signature] = useState(
    !isSSR() && window.localStorage.getItem("activeAccount")
  );

  const [deleted, setDeleted] = useState(false);

  let metadata, app;

  try {
    metadata = JSON.parse(post.json_metadata);
    app = metadata.app.split("/")[0];
  } catch {
    return null;
  }

  const body =
    useStrippedSlicedBody(post.body, 300) || "This post is unavailable";

  const content = useParsedBody(body);

  const handleRemove = async () => {
    if (!activeAccount) return;

    const response = await removeBookmark(
      post.author,
      post.permlink,
      folder,
      activeAccount.name,
      signature || _signature || "",
      publicKey || "",
      proxy === "hivesigner"
    );

    if (!response.ok) {
      return toast("Error while removing bookmark.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3000
      });
    }

    setDeleted(true);
    toast("Bookmark successfully deleted.", {
      type: "success",
      theme: isDarkMode ? "dark" : "light",
      autoClose: 3000
    });
  };

  const isThread = post.root_author === "leothreads";
  return (
    <div
      className={classNames(
        "flex w-full hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] border-b border-pri dark:border-pri-d transition-colors duration-150",
        {
          hidden: deleted
        }
      )}
    >
      <Link
        prefetch="render"
        className="w-full"
        to={
          isThread
            ? `/threads/view/${post.author}/${post.permlink}`
            : `/@${post.author}/${post.permlink}`
        }
      >
        <div className="flex flex-col w-full p-4 cursor-pointer">
          <div className="flex flex-row justify-between w-full mt-2 mb-3">
            <div className="flex flex-row items-center gap-x-2.5">
              <div className="relative">
                <SmallAvatar12 author={post.author} className="shrink-0" />

                <span className="absolute -right-1 -bottom-1">
                  <Reputation reputation={post.author_reputation} />
                </span>
              </div>

              <Suspense fallback={<></>}>
                <ProfileLink accountName={post.author} referrer={post.author}>
                  <div className="flex flex-col">
                    <DisplayName authorName={post.author} name={post.author} />
                    <div className="flex items-center gap-x-1.5">
                      <AccountName author={post.author} className="leading-4" />
                      <span className="text-gray-600 dark:text-gray-500 font-bold">
                        ·
                      </span>
                      <TimeSincePublish publishTime={post.created} />
                    </div>
                  </div>
                </ProfileLink>
              </Suspense>
            </div>

            <div
              onClick={ev => {
                ev.preventDefault();
                handleRemove();
              }}
              className="p-2 h-8 w-8 right-4 z-50 flex items-center justify-center rounded-full hover:bg-acc/[.15] transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faBookmarkSolid} className="text-acc" />
            </div>
          </div>

          {metadata.image && (
            <div className="relative flex w-full max-h-[18rem] items-center rounded-lg border border-pri dark:border-pri-d overflow-hidden mt-3">
              <img
                className="w-full h-full object-cover"
                src={metadata.image[0] || null}
                alt=""
              />
            </div>
          )}

          {post.title && (
            <div className="flex mt-3 mb-3">
              <span className="font-bold text-2xl leading-snug">
                {post.title || ""}
              </span>
            </div>
          )}

          <p
            className={classNames(
              "prose prose-sm dark:prose-invert leading-5",
              {
                "mt-2": isThread
              }
            )}
          >
            {content}
          </p>
        </div>
      </Link>
    </div>
  );
}

export function BookmarksLoader() {
  return (
    <div className="flex flex-1 flex-col divide-y divide-solid divide-pri/50 dark:divide-pri-d/50 animate-pulse">
      {[...Array(5).keys()].map((index: number) => (
        <div key={index} className="flex items-center gap-x-3 py-3 px-3 w-full">
          <span className="flex shrink-0 w-12 h-12 rounded-full bg-gray-200 dark:bg-zinc-700" />

          <span className="flex w-1/3 h-3 bg-gray-200 dark:bg-zinc-700 rounded-lg" />
        </div>
      ))}
    </div>
  );
}
