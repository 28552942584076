import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useLeoPolls, HiveFetchState } from "~/hooks/contentHooks";
import { UnvotedPollView } from "./UnvotedPollView";
import { VotedPollView } from "./VotedPollView";
import { LoadingPollView } from "./LoadingPollView";
import { useAppStore } from "~/store";

//@ts-ignore
export default function PollView({ authorPerm, pollOptions }): JSX.Element {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [pollVoteState, setPollVoteState] = useState(0);
  const [fetchState, pollContent] = useLeoPolls(
    authorPerm.permlink,
    activeAccount?.name,
    pollVoteState
  );

  useEffect(() => {
    if (pollVoteState === 1) setTimeout(() => setPollVoteState(2), 3500);
    if (pollVoteState === 2) setTimeout(() => setPollVoteState(3), 5500);
  }, [pollVoteState]);

  if (pollVoteState === 0) {
    if (fetchState === HiveFetchState.Initial)
      // return <Spinner text="Fetching results from cache" />;
      return (
        <LoadingPollView
          pollOptions={pollOptions}
          pollContent={pollContent}
          authorPerm={authorPerm}
          author={activeAccount?.name}
        />
      );

    if (pollContent) {
      //@ts-ignore
      const { account } = pollContent;
      if (!account.voted)
        return (
          <UnvotedPollView
            setPollVoteState={setPollVoteState}
            pollOptions={pollOptions}
            pollContent={pollContent}
            authorPerm={authorPerm}
            author={activeAccount?.name}
          />
        );
    } else {
      return (
        <UnvotedPollView
          pollOptions={pollOptions}
          authorPerm={false}
          author={false}
        />
      );
    }
    if (fetchState === HiveFetchState.Fetched) {
      return (
        <VotedPollView
          pollOptions={pollOptions}
          authorPerm={authorPerm}
          author={activeAccount?.name}
          pollContent={pollContent}
        />
      );
    }
  } else {
    return (
      <div>
        {pollVoteState === 1 && (
          <div className="relative flex justify-center items-center">
            <UnvotedPollView
              setPollVoteState={setPollVoteState}
              pollOptions={pollOptions}
              pollContent={pollContent}
              authorPerm={authorPerm}
              author={activeAccount?.name}
              className="opacity-50 pointer-events-none"
            />

            {/* <Spinner text="Writing custom json to hive" /> */}
            <FontAwesomeIcon
              icon={faSpinner}
              size="lg"
              className="absolute animate-spin"
            />
          </div>
        )}
        {pollVoteState === 2 && (
          <div className="relative flex justify-center items-center">
            <UnvotedPollView
              setPollVoteState={setPollVoteState}
              pollOptions={pollOptions}
              pollContent={pollContent}
              authorPerm={authorPerm}
              author={activeAccount?.name}
              className="opacity-50 pointer-events-none"
            />

            {/* <Spinner text="Writing custom json to hive" /> */}
            <FontAwesomeIcon
              icon={faSpinner}
              size="lg"
              className="absolute animate-spin"
            />
          </div>
        )}
        {pollVoteState === 3 && (
          <VotedPollView
            pollOptions={pollOptions}
            authorPerm={authorPerm}
            author={activeAccount?.name}
            pollContent={pollContent}
          />
        )}
      </div>
    );
  }
}
