import { addDays } from "date-fns";
import {
  authorPermToString,
  type HiveContent,
  type ParsedAccount,
  type PossibleHiveContent
} from "~/utils/hive";
import type { Thread } from "./leocache";

export enum ThreadSort {
  Latest = "latest",
  Trending = "trending"
}

export interface ThreadIndexed extends Thread {
  stats: any;
  children: number;
  body: unknown;
  hashtags: any;
  created: string | number | Date;
  index: number;
  fake?: true;
}

export interface PossibleThreadContent {
  thread: ThreadIndexed;
  content: PossibleHiveContent;
}

export interface ThreadContent {
  thread: ThreadIndexed;
  content: HiveContent;
}

export type FeaturedThreadTags = { tag: string; count: number }[];

export function createFakeThread(
  pushStartToThreads: (item: PossibleThreadContent) => void,
  commentOperation: any,
  index: number,
  activeAccount: ParsedAccount
) {
  const now = new Date();
  const creationDate = "";
  const cashoutTime = addDays(now, 7).toISOString(); // TODO: 7 can be extracted as CASHOUT TIME const
  console.log({ commentOperation });
  const {
    author,
    permlink,
    parent_permlink,
    parent_author,
    title,
    body,
    json_metadata
  } = commentOperation;

  setTimeout(() => {
    pushStartToThreads({
      index,
      author,
      permlink,
      created: now,
      author_perm: authorPermToString(commentOperation),
      deleted: false,
      fake: true,
      title,
      json_metadata,
      parent_author,
      parent_permlink,
      body,
      children: 0,
      created: creationDate,
      last_update: creationDate,
      active_votes: [],
      author_reputation: activeAccount.reputation as number,
      cashout_time: cashoutTime,
      category: "hive-167922", // TODO: extract as LEOFINANCE community,
      depth: 1,
      root_author: "leothreads"
    });
  }, 500);
}

interface PrepareThread {
  operation: unknown;
  activeAccount: ParsedAccount;
}

export function prepareFakeThread({
  operation,
  activeAccount
}: PrepareThread): Thread {
  const now = new Date();
  const creationDate = "";
  const cashoutTime = addDays(now, 7).toISOString(); // TODO: 7 can be extracted as CASHOUT TIME const

  const {
    author,
    permlink,
    parent_permlink,
    parent_author,
    title,
    body,
    json_metadata
  } = operation;

  return {
    author,
    permlink,
    deleted: false,
    created: now,
    replies: [],
    author_perm: authorPermToString(operation),
    fake: true,
    parent_permlink,
    parent_author,
    title,
    json_metadata,
    body,
    children: 0,
    last_update: creationDate,
    active_votes: [],
    author_reputation: activeAccount.reputation as number,
    cashout_time: cashoutTime,
    category: "hive-167922", // TODO: extract as LEOFINANCE community,
    depth: 1,
    root_author: "leothreads"
  };
}
