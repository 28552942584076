import classNames from "classnames";

interface PriceTickerWrapperProps {
  children: React.ReactNode;
}

export function PriceTickerWrapper({ children }: PriceTickerWrapperProps) {
  return (
    <div className="flex flex-wrap items-stretch space-between gap-3">
      {children}
    </div>
  );
}

interface PriceTickerProps {
  token: string;
  price: number | undefined;
  change: number | undefined;
}

export function PriceTicker({ token, price, change }: PriceTickerProps) {
  if (price === undefined || change === undefined) return null;

  return (
    <div className="relative flex flex-col flex-1 p-4 pb-2 bg-gray-100 dark:bg-zinc-800 rounded-md">
      <div className="flex flex-row items-center gap-3">
        <strong className="uppercase font-semi">{token}</strong>
        <span
          className={classNames(
            "flex py-0 px-2 rounded-2xl bg-red-500/[0.2] text-red-500 text-xxs font-bold",
            { "!bg-green-500/[0.2] !text-green-500": change > 0 }
          )}
        >
          {change > 0 ? `+${change.toFixed(2)}` : change.toFixed(2)}%
        </span>
      </div>

      <strong className="text-2xl font-bold mt-3 text-acc">
        ${price.toFixed(3)}
      </strong>

      <a
        href="https://coingecko.com"
        target="_blank"
        rel="noreferrer noopenner"
        className="text-xxs text-center text-zinc-500 mt-4 hover:underline"
      >
        Market value by CoinGecko
      </a>
    </div>
  );
}

