import { useNavigate, Link } from "@remix-run/react";
import { useMemo } from "react";
import rehypeRemark from "rehype-remark";
import rehypeSanitize from "rehype-sanitize";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import { unified } from "unified";
import { useSlicedString } from "~/hooks/parseHooks";
import { HiveContent } from "~/utils/hive";
import { stripMarkdownToText, removeHTMLTags } from "~/utils/markdown";
import { sliceString } from "~/utils/parse";
import { TimeSincePublish } from "../TimeSincePublish";

interface FullPostPreviewProps {
  url: string;
  post: HiveContent;
}

export function FullPostPreview({
  url,
  post: {
    title,
    body,
    json_metadata,
    author,
    permlink,
    author_reputation,
    created
  }
}: FullPostPreviewProps) {
  if (author_reputation < 75) return;
  const parsedTitle = useSlicedString(title, 85);

  const parsedBody = useMemo(() => {
    const parsed = unified()
      .use(remarkParse)
      .use(remarkRehype)
      .use(stripMarkdownToText)
      .use(rehypeSanitize)
      .use(rehypeRemark)
      .use(remarkStringify)
      .processSync(removeHTMLTags(body));

    return sliceString(String(parsed), 300);
  }, [body]);

  const [firstImage, app] = useMemo(() => {
    let metadata;
    try {
      metadata = JSON.parse(json_metadata);
    } catch {
      return [null, null];
    }

    if (metadata === undefined) {
      return [null, null];
    }
    const { image } = metadata;
    if (image === undefined) {
      return [null, null];
    }
    return [image[0], metadata.app.split("/")[0]];
  }, [json_metadata]) as unknown as string | null;

  const appColors = {
    actifit: "rgb(225 29 72)",
    peakd: "rgb(87 83 78)",
    ecency: "rgb(2 132 199)"
  };

  const navigate = useNavigate();

  if (!author || !body) return <a href={url}>{url}</a>;

  return (
    <Link
      to={`https://inleo.io/@${author}/${permlink}`}
      target="_blank"
      rel="noreferrer noopenner"
      className="flex flex-col w-full max-w-full p-[1px] border border-pri dark:border-pri-d rounded-xl overflow-hidden cursor-pointer hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150 !no-underline"
    >
      {firstImage && (
        <div className="relative flex w-full rounded-lg p-1">
          <div className="relative flex flex-1 w-full h-0 pb-[32.84%] rounded-lg overflow-hidden">
            <img
              src={firstImage}
              className="absolute inset-0 w-full h-full object-cover !m-0"
              loading="lazy"
              alt={title}
            />
          </div>
        </div>
      )}

      <div className="p-2 py-3 flex flex-col justify-between leading-normal">
        <div className="flex flex-1 flex-col px-2">
          <div className="text-sm text-gray-600 flex items-center">
            <div className="py-1.5 px-4 rounded-md bg-orange-600/[.15] text-orange-600 dark:bg-orange-500/[.15] dark:text-orange-500 uppercase text-xs font-semibold text-center">
              HIGH REPUTATION AUTHOR
            </div>
            {app && (
              <div className="py-1.5 px-4 rounded-md bg-green-600/[.15] text-green-600 dark:bg-green-500/[.15] dark:text-green-500 uppercase text-xs font-semibold text-center ml-auto">
                {app === "leothreads" ? "inleo" : app}
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 pt-3 pb-5">
            <div className="flex w-10 h-10 relative overflow-hidden border border-pri dark:border-pri-d rounded-full">
              <img
                className="w-full h-full object-cover object-center m-0"
                src={`https://img.inleo.io/u/${author}/avatar/small`}
                alt=""
              />
            </div>
            <div className="flex flex-col text-sm font-medium gap-y-0.5">
              <p className="text-pri dark:text-pri-d leading-none m-0">
                @{author}
              </p>
              <div className="text-xs text-pri/60 dark:text-pri-d/60 m-0 mt-0.5">
                <TimeSincePublish publishTime={created} />
              </div>
            </div>
          </div>

          <div className="text-gray-900 dark:text-white font-bold text-xl">
            {parsedTitle}
          </div>
          <p className="text-gray-500 dark:text-zinc-400 text-sm mt-2 mb-0 font-normal">
            {parsedBody} <span className="text-acc font-medium">See more</span>
          </p>
        </div>

        {/* ---- */}
      </div>
    </Link>
  );
}
