import React from "react";
import { FloatingPortal } from "@floating-ui/react";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import MarkdownEditor, { MarkdownEditorTypes } from "../MarkdownEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useAppStore } from "~/store";
import { prepareAuthorization } from "~/utils/infra";
import { decryptPostBody } from "~/utils/leocache";
import type { PossibleHiveContent } from "~/utils/hive";

interface EditThreadProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: "thread" | "post";
  content: PossibleHiveContent;
  handleEdit: () => Promise<void>;
}
export default function EditThread({
  setOpen,
  type,
  content,
  handleEdit
}: EditThreadProps) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [body, setBody] = React.useState<string>(content?.body || "");
  const [decrypting, setDecrypting] = React.useState(false);

  const metadata = React.useMemo(() => {
    let metadata = {};
    try {
      metadata = JSON.parse(content?.json_metadata);
    } catch {
      metadata = {};
    }

    return metadata;
  }, [content]);

  React.useEffect(() => {
    if (!metadata.encrypted) return;

    void (async function () {
      setDecrypting(true);

      try {
        const authorization = await prepareAuthorization(activeAccount);
        const decrypted_body = await decryptPostBody(
          authorization,
          content.author,
          body
        );

        if (!decrypted_body) {
          setDecrypting(false);
          return;
        }

        setBody(decrypted_body);
        setDecrypting(false);
        return;
      } catch (e) {
        setDecrypting(false);
      }
    })();
  }, [metadata, activeAccount, content]);

  return (
    <FloatingPortal>
      <FloatingHTMLOverlay
        onClick={ev => ev.stopPropagation()}
        className="bg-overlay z-[1000] flex justify-center py-[10vh]"
        lockScroll
      >
        <div className="relative py-8 pc:py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-full h-fit pc:w-4/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full min-w-[340px] pc:min-w-[480px]">
          <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-pri-d">
            <strong className="text-lg">
              Edit {type === "thread" ? "Thread" : "Post"}
            </strong>
            <div
              onClick={() => setOpen(false)}
              className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </header>

          <div className="relative flex flex-col border border-pri dark:border-pri-d border-b-0">
            <React.Suspense fallback={<></>}>
              {decrypting ? null : (
                <MarkdownEditor
                  type={
                    type === "thread"
                      ? MarkdownEditorTypes.Thread
                      : MarkdownEditorTypes.Post
                  }
                  initialContent={body}
                  handlePost={handleEdit}
                />
              )}
            </React.Suspense>
          </div>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}

