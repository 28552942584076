import classNames from "classnames";
import moment from "moment";
import { ClientOnly } from "remix-utils/client-only";
import Popover from "~/components/Popover";

interface TimeSincePublishProps {
  publishTime: string;
  utc?: boolean;
  className?: string;
}

export function TimeSincePublish({
  publishTime,
  utc,
  className
}: TimeSincePublishProps) {
  let untouched = publishTime;
  if (publishTime === "") publishTime = moment().utc(false);
  const timeElapsed = moment.utc(publishTime).fromNow();
  const timestamp = moment(publishTime).unix().toString();

  return (
    <>
      <span
        itemProp="datePublished"
        content={untouched}
        id={`timestamp-tooltip-${timestamp}`}
        className={classNames(
          "text-xs text-gray-600 dark:text-gray-400",
          className
        )}
      >
        {timeElapsed}
      </span>
      <Popover
        anchorId={`timestamp-tooltip-${timestamp}`}
        content={moment(publishTime).format("MMM DD yyyy h:mm a")}
        place="top"
      />
    </>
  );
}
