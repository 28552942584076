import * as React from "react";

interface IconTextButtonProps {
  children: React.ReactNode;
}

export default function IconText({ children }: IconTextButtonProps) {
  return (
    <span className="group flex text-lg items-center mx-0.5 pc:mx-0 py-1.5 pl-1.5 pc:py-0.5 pc:pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 transition-colors cursor-pointer">
      {children}
    </span>
  );
}

