import classNames from "classnames";
import { ClientOnly } from "remix-utils/client-only";

interface ProgressProps {
  size?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: "butt" | "round" | "square";
  label?: string | boolean;
  labelClassName?: string;
  className?: string;
  expandOnWarn?: boolean; // when progress lower than 20 expand indicator (use for remaning content length on thread editor)
}

export default function CircularProgress(props: ProgressProps) {
  const {
    size = 32,
    progress = 0,
    trackWidth = 2,
    trackColor = `#ddd`,
    indicatorWidth = 2,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label = false,
    labelClassName = "",
    className = "",
    expandOnWarn = false
  } = props;

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <ClientOnly>
      {() => (
        <div
          className={classNames("svg-pi-wrapper", className)}
          style={{ width: size, height: size }}
        >
          <svg
            className="relative items-center justify-center svg-pi origin-center transition-transform duration-150"
            style={{
              width: size,
              height: size,
              transform: expandOnWarn
                ? `rotate(-90deg) scale(1.2)`
                : `rotate(-90deg) scale(1)`
            }}
          >
            <circle
              className={classNames("svg-pi-track", {
                "opacity-0": +label < 0
              })}
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={trackColor}
              strokeWidth={trackWidth}
            />
            <circle
              className={classNames("svg-pi-indicator", {
                "opacity-0": +label < 0
              })}
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={indicatorColor}
              strokeWidth={indicatorWidth}
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              strokeLinecap={indicatorCap}
            />
          </svg>
          {label && (
            <span
              className={classNames(
                "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-150",
                labelClassName
              )}
              style={{
                color: indicatorColor,
                ...(!labelClassName && { fontSize: +label >= 0 ? 10 : 14 })
              }}
            >
              {label}
            </span>
          )}
        </div>
      )}
    </ClientOnly>
  );
}

interface RectangularProgressProps {
  width?: number;
  height?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: "butt" | "round" | "square";
  label?: string | boolean;
  expandOnWarn?: boolean;
  borderRadius?: number;
}

export function RectangularProgress(props: RectangularProgressProps) {
  const {
    width = 32,
    height = 8,
    progress = 0,
    trackWidth = 2,
    trackColor = `#ddd`,
    indicatorWidth = 2,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label = false,
    expandOnWarn = false,
    borderRadius = 0
  } = props;

  const radius =
      height / 2 - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <div
      className="rectangular-pi-wrapper"
      style={{
        width,
        height,
        borderRadius
      }}
    >
      <svg
        className="relative transition-transform duration-150"
        style={{
          width,
          height,
          transform: expandOnWarn ? `scale(1.2)` : `scale(1)`
        }}
      >
        <rect
          className={classNames("rectangular-pi-track", {
            "opacity-0": +label < 0
          })}
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          stroke={trackColor}
          strokeWidth={trackWidth}
          rx={borderRadius}
        />
        <rect
          className={classNames("rectangular-pi-indicator", {
            "opacity-0": +label < 0
          })}
          x={0}
          y={0}
          width={width * (1 - progress / 100)}
          height={height}
          fill="transparent"
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeLinecap={indicatorCap}
          rx={borderRadius}
        />
      </svg>
      {label && (
        <span
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-150"
          style={{ color: indicatorColor, fontSize: +label >= 0 ? 10 : 14 }}
        >
          {label}
        </span>
      )}
    </div>
  );
}
