import {
  faCheck,
  faHeart,
  faBorderAll,
  faFolder
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Bookmark } from "~/utils/leocache";

export function FolderPreview({
  folder,
  selected,
  onClick
}: {
  folder: Bookmark;
  selected: string | null;
  onClick: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const special_folders = {
    favorites: "bg-[#ed2b4b] dark:bg-[#e31337]"
  };

  return (
    <button
      type="button"
      className="relative flex items-center w-full gap-x-3 hover:bg-pri-d/[0.05] dark:hover:bg-pri/[0.05] py-3 px-3 pl-7 bg-pri dark:bg-pri-d transition-colors duration-150"
      onClick={() => onClick(selected === folder.folder ? null : folder.folder)}
    >
      {selected === folder.folder && (
        <span className="absolute left-1 mt-1.5 flex w-6 h-6 rounded-full">
          <FontAwesomeIcon icon={faCheck} size="sm" fixedWidth />
        </span>
      )}

      <span
        className={classNames(
          "flex justify-center items-center shrink-0 w-12 h-12 rounded-full",
          ...Object.entries(special_folders).map(([key, value]) => ({
            ...(key.toLowerCase() === folder.folder.toLowerCase()
              ? { [value]: true }
              : {
                  "bg-gray-200 dark:bg-zinc-700": true
                })
          }))
        )}
      >
        <FontAwesomeIcon
          icon={
            folder.folder.toLowerCase() === "favorites"
              ? faHeart
              : folder.folder === ""
              ? faBorderAll
              : faFolder
          }
          size="lg"
          fixedWidth
        />
      </span>

      <span className="text-base text-center font-semibold text-pri dark:text-pri-d">
        {folder.folder || "All"}
      </span>

      <span className="text-sm font-semibold text-pri/70 dark:text-pri-d/70 ml-auto pr-4">
        {folder.permlinks.length}
      </span>
    </button>
  );
}
