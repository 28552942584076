import { memo } from "react";
import { FullThread } from "./FullThread";
import type { Thread } from "~/utils/leocache";

interface ThreadViewProps {
  threadContent: Thread;
  inView?: boolean;
  liveMode?: boolean;
  fake?: boolean;
  feedType?: string;
  beforeTransition?: Function;
}

export const ThreadView = memo(
  ({ threadContent, fake, feedType, beforeTransition }: ThreadViewProps) => {
    return (
      <FullThread
        threadContent={threadContent}
        fake={fake}
        feedType={feedType}
        beforeTransition={beforeTransition}
      />
    );
  }
);

ThreadView.displayName = "ThreadView";

export function LoadingPostPreview() {
  return (
    <div className="border border-pri dark:border-pri-d rounded">
      <div className="divide-y divide-pri dark:divide-pri-d motion-safe:animate-pulse">
        <div className="h-64 w-full bg-slate-200 dark:bg-zinc-700 overflow-hidden" />
        <div className="justify-center h-16 px-2 py-2 flex flex-col gap-2">
          <div className="grid gap-2 grid-cols-3">
            <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded col-span-1" />
          </div>
          <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded" />
          <div className="grid gap-5 grid-cols-3">
            <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded col-span-2" />
          </div>
        </div>
      </div>
    </div>
  );
}
