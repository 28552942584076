export function threadSlugify(inputString: string): string {
    if (inputString === undefined) return;
    // Remove special characters except for hyphens and underscores
    const cleanedString = inputString.replace(/[^\w\s-]/g, '');
  
    // Replace spaces with hyphens
    const slug = cleanedString?.trim().replace(/\s+/g, '-');
  
    // Remove all hyphens
    return slug.replace(/-/g, '').toLowerCase();
  }
  