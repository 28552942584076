// store
import { FloatingPortal } from "@floating-ui/react";
import {
  faArrowLeftLong,
  faCheck,
  faChevronRight,
  faCrown,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import useEventListener from "~/hooks/useEventListener";

// utils
import { cn } from "~/utils/cn";
import { BackgroundGradientAnimation } from "../layout/BackgroundGradient";
import {
  SmallAvatar10,
  SmallAvatar12,
  SmallAvatar8
} from "../format/SmallAvatar";
import { CreatorSubBadge, CreatorSubCrown } from "../CreatorSub";
import { leocache } from "~/utils/leocache";
import { useAppStore } from "~/store";
import { useNavigate } from "@remix-run/react";
import { fetchRecurrentOperations, type ParsedAccount } from "~/utils/hive";
import { getCookie } from "~/utils/cookie";
import { toast } from "react-toastify";
import { hiveToken } from "~/utils/transactions";
import hiveSigner from "~/images/login/hivesigner.svg";
import keychain from "~/images/login/keychain-brand.svg";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useCreatorSubscribers } from "~/hooks/useSubscriptions";
import classNames from "classnames";

type Screen = "features" | "payment" | "success";

export default function SubscribeCreatorButton({
  creator
}: {
  creator: string;
}) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [subscribable, setSubscribable] = useState(false);
  const [screen, setScreen] = useState<Screen>("features");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const creatorSubs = useCreatorSubscribers(creator);

  const isSubscribed = creatorSubs?.includes(activeAccount?.name);

  useEffect(() => {
    void (async function () {
      const result = await leocache.getInfraSubscribable(creator);
      setSubscribable(result);
    })();
  }, [creator]);

  useEventListener("keydown", event => {
    if (!open) return;

    if (event.key === "Escape") {
      setOpen(false);
    }
  });

  useEffect(() => {
    const html = document.documentElement;

    if (open) {
      html.style.overflow = "hidden";
    } else {
      html.style.overflow = "auto";
    }
  }, [open]);

  function handleOpenModal() {
    if (isSubscribed) return null;

    if (!activeAccount) {
      return navigate("/login");
    }

    setOpen(true);
  }

  const props = { activeAccount: activeAccount!, creator, setScreen, setOpen };
  const screen_renderer = {
    features: <FeaturesScreen {...props} />,
    payment: <PaymentScreen {...props} />,
    success: <SuccessScreen {...props} />
  };

  if (!subscribable) return null;
  return (
    <Fragment>
      <button
        type="button"
        className={cn(
          "relative flex justify-center items-center gap-x-2 py-1 px-5 pl-4 h-fit min-h-[38px] min-w-[76px] rounded-full bg-c-subscriptions/20 text-c-subscriptions border border-c-subscriptions/30 text-xs font-semibold hover:bg-c-subscriptions/30 transition-colors duration-150 z-10 disabled:opacity-50 disabled:pointer-events-none",
          {
            "bg-c-subscriptions text-pri hover:bg-c-subscriptions/80":
              isSubscribed
          }
        )}
        onClick={() => handleOpenModal()}
      >
        <FontAwesomeIcon icon={faCrown} size="sm" fixedWidth />
        {isSubscribed ? "Subscribed" : "Subscribe"}
      </button>

      {activeAccount ? (
        <FloatingPortal>
          <AnimatePresence>
            {open ? (
              <Fragment>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  className="fixed inset-0 w-screen h-screen bg-black/50 z-1000"
                  onClick={() => setOpen(false)}
                />

                <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[10000]">
                  <motion.div
                    initial={{ opacity: 0, y: 6 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 6 }}
                    transition={{ duration: 0.15 }}
                    className="min-w-[340px] sm:min-w-[460px] h-[500px] bg-pri dark:bg-pri-d rounded-xl drop-shadow shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)] overflow-x-hidden overflow-y-auto"
                  >
                    <header className="sticky top-0 z-50 flex flex-row items-center justify-between py-1 px-3 gap-x-6 bg-white/80 dark:bg-black/80 backdrop-blur">
                      <button
                        type="button"
                        className="flex justify-center items-center size-9 rounded-full text-pri dark:text-pri-d transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10"
                        onClick={() =>
                          screen === "payment"
                            ? setScreen("features")
                            : setOpen(false)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeftLong}
                          size="sm"
                          fixedWidth
                        />
                      </button>

                      {screen === "features" ? (
                        <button
                          type="button"
                          className="flex py-2 px-4 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri font-semibold text-xs transition-opacity duration-150 hover:opacity-80"
                          onClick={() =>
                            setScreen(prev =>
                              prev === "features" ? "payment" : "features"
                            )
                          }
                        >
                          Subscribe: 5 HBD
                        </button>
                      ) : null}
                    </header>

                    <div className="relative w-full h-full flex flex-col">
                      <AnimatePresence>
                        {screen_renderer[screen]}
                      </AnimatePresence>
                    </div>
                  </motion.div>
                </div>
              </Fragment>
            ) : null}
          </AnimatePresence>
        </FloatingPortal>
      ) : null}
    </Fragment>
  );
}

interface SharedScreenProps {
  activeAccount: ParsedAccount;
  creator: string;
  setScreen: React.Dispatch<React.SetStateAction<Screen>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function FeaturesScreen(props: SharedScreenProps) {
  return (
    <motion.div
      layout
      initial={{ y: 8, scale: 0.97, opacity: 0 }}
      animate={{ y: 0, scale: 1, opacity: 1 }}
      exit={{ y: 8, scale: 0.97, opacity: 0 }}
      transition={{ duration: 0.25 }}
      className="absolute w-full h-full flex flex-col"
    >
      <div className="relative flex flex-col shrink-0 w-full overflow-hidden">
        <div className="relative flex flex-col min-h-[150px] sm:min-h-[200px] [mask-image:linear-gradient(to_top,transparent,black_30px,black_100%,transparent)]">
          <div className="absolute flex flex-col w-full h-full overflow-hidden pointer-events-none">
            <BackgroundGradientAnimation
              containerClassName="w-full h-full z-0"
              firstColor="#8d72fc"
              secondColor="#7856ff"
              thirdColor="#1d9bf0"
              fourthColor="#f91880"
              fifthColor="#00ba7c"
            />
            <span className="absolute inset-0 w-full h-full bg-pri/50 dark:bg-pri-d/50 z-10" />
          </div>

          <div className="relative flex flex-1 flex-col p-7 gap-y-3.5 drop-shadow z-20">
            <SmallAvatar12
              author={props.creator}
              className="border-none outline-none"
            />

            <div className="flex flex-col gap-y-px">
              <strong className="text-lg font-semibold">
                Subscribe to @{props.creator}
              </strong>

              <p className="text-sm text-pri/70 dark:text-pri-d/70">
                Subscribe and access author's exclusive content.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col w-full p-5 pt-3 gap-y-8">
        <div className="flex flex-col justify-center items-center gap-y-4">
          <div className="flex items-center gap-x-2 py-1.5 px-2.5 rounded-lg bg-c-subscriptions/20 text-c-subscriptions">
            <CreatorSubBadge />
            <span className="uppercase text-xs font-semibold">
              Subscribers Only
            </span>
          </div>

          <div className="flex flex-col text-center gap-y-px">
            <strong className="text-lg font-semibold">Exclusive Content</strong>

            <p className="text-sm text-pri/70 dark:text-pri-d/70">
              Subscribe and access @{props.creator}'s Subscribers-Only content.
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center gap-y-4">
          <CreatorSubCrown />

          <div className="flex flex-col text-center gap-y-px">
            <strong className="text-lg font-semibold">Shining Badge</strong>

            <p className="text-sm text-pri/70 dark:text-pri-d/70">
              Subscribe and get our brand new Subscriber Badge.
            </p>
          </div>

          <div className="flex flex-col gap-y-4 p-3 my-2 rounded-xl border border-pri dark:border-pri-d drop-shadow-sm shadow-[0_0_12px_3px_rgb(255_255_255_/_10%)]">
            <div className="flex flex-row items-center gap-x-3">
              <SmallAvatar8 author={props.activeAccount.name} />

              <div className="flex flex-col">
                <div className="flex items-center gap-x-2">
                  <span className="text-sm font-semibold">
                    {props.activeAccount.name}
                  </span>
                  <CreatorSubBadge />
                </div>

                <span className="text-xs text-pri/50 dark:text-pri-d/50">
                  @{props.activeAccount.name}
                </span>
              </div>
            </div>

            <span className="flex w-60 h-1.5 ml-11 rounded-full bg-gray-300 dark:bg-zinc-700" />
          </div>
        </div>

        <div className="flex flex-col justify-center items-center gap-y-2">
          <button
            type="button"
            className="flex items-center py-3.5 px-5 gap-x-2 w-fit min-w-[140px] rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri font-semibold text-sm transition-opacity duration-150 hover:opacity-80"
            onClick={() => props.setScreen("payment")}
          >
            <span className="leading-none">Subscribe: 5 HBD</span>
            <FontAwesomeIcon icon={faChevronRight} size="sm" fixedWidth />
          </button>

          <small className="text-center text-balance text-xs font-medium text-pri/50 dark:text-pri-d/50">
            Click and continue transfer with Keychain or Hivesigner.
          </small>
        </div>
      </div>
    </motion.div>
  );
}

function PaymentScreen(props: SharedScreenProps) {
  const [activeAccount, isDarkMode] = useAppStore(store => [
    store.account.activeAccount,
    store.settings.dark
  ]);

  const [autoRenewal, setAutoRenewal] = useState(false);
  const [autoRenewalDisabled, setAutoRenewalDisabled] = useState(true);

  const [proxy, setProxy] = useState(null);
  const [loading, setLoading] = useState(false);
  const price = 5;

  useEffect(() => {
    if (typeof window === "undefined") return;

    const session = getCookie("__session");
    setProxy(session.proxy);
  }, []);

  useEffect(() => {
    if (!activeAccount) return;

    void (async function () {
      const ops = await fetchRecurrentOperations(activeAccount.name);
      // const ops = ["yarrak"];
      setAutoRenewalDisabled(ops?.length > 0);
    })();
  }, [activeAccount, autoRenewal]);

  const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      return toast("There is an error while copying text to clipboard.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    navigator.clipboard.writeText(text);
    toast(`${text} copied to clipboard`, {
      type: "success",
      theme: isDarkMode ? "dark" : "light",
      autoClose: 3_000
    });
  };
  const transfer = async (type: "hivesigner" | "keychain") => {
    if (!activeAccount || !proxy) {
      return toast("You need to sign in before this action.", {
        type: "error",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });
    }

    if (type === "keychain" && proxy !== "keychain") {
      return toast("You need to sign in with Hive Keychain.", {
        type: "error",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });
    }

    if (type === "hivesigner" && proxy !== "hivesigner") {
      return toast("You need to sign in with HiveSigner.", {
        type: "error",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });
    }

    try {
      setLoading(true);

      if (autoRenewal) {
        if (type === "keychain") {
          window.hive_keychain.requestRecurrentTransfer(
            activeAccount.name,
            "leosubscriptions",
            `${price}.000`,
            "HBD",
            `subscribe:${props.creator}`,
            720,
            12,
            response => {
              console.log(response);
              if (response.success) {
                // Handle success
                props.setScreen("success");
              } else {
                // Handle error
                setLoading(false);
              }
            }
          );
        } else {
          const hivesignerUrl = `https://hivesigner.com/sign/recurrent_transfer?from=${activeAccount.name}&to=leosubscriptions&amount=5.000%20HBD&memo=subscribe:${props.creator}&recurrence=720&executions=12`;
          window.open(hivesignerUrl, "_blank");
        }
      } else {
        // Existing single payment logic
        await hiveToken(activeAccount.name, "transfer", {
          to: "leosubscriptions",
          quantity: price,
          memo: `subscribe:${props.creator}`,
          symbol: "HBD"
        });
        setLoading(false);
        props.setScreen("success");
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <motion.div
      layout
      initial={{ y: 8, scale: 0.97, opacity: 0 }}
      animate={{ y: 0, scale: 1, opacity: 1 }}
      exit={{ y: 8, scale: 0.97, opacity: 0 }}
      transition={{ duration: 0.25 }}
      className="absolute w-full h-full flex flex-col"
    >
      <AnimatePresence>
        {loading ? (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 w-full h-full flex justify-center items-center bg-black/80 z-10"
          >
            <FontAwesomeIcon
              icon={faSpinner}
              size="lg"
              className="animate-spin"
            />
          </motion.span>
        ) : null}
      </AnimatePresence>
      <div className="flex flex-1 flex-col text-pri dark:text-pri-d px-5 pt-3">
        <strong className="text-lg font-semibold pb-4">
          Choose a Payment Method
        </strong>
        <div className="flex flex-col items-center justify-center gap-y-6">
          <div className="flex w-full p-3 rounded-xl border border-c-subscriptions/30 bg-c-subscriptions/10">
            <label
              htmlFor="agree"
              className={cn(
                "group flex items-start gap-x-3 pl-1 cursor-pointer hover:text-c-subscriptions transition-all duration-150",
                {
                  "text-c-subscriptions/90": !autoRenewal,
                  "text-c-subscriptions": autoRenewal,
                  "cursor-not-allowed": autoRenewalDisabled
                }
              )}
              aria-disabled={autoRenewalDisabled}
            >
              <input
                id="agree"
                type="checkbox"
                className="opacity-0"
                checked={autoRenewal}
                disabled={autoRenewalDisabled}
                onChange={event => setAutoRenewal(event.target.checked)}
                hidden
              />

              <span
                className={cn(
                  "flex shrink-0 items-center justify-center w-5 h-5 mt-0.5 rounded-md border border-c-subscriptions/40 group-hover:bg-c-subscriptions/10 cursor-pointer transition-colors duration-150",
                  {
                    "bg-c-subscriptions/20": autoRenewal
                  }
                )}
              >
                {autoRenewal && (
                  <FontAwesomeIcon icon={faCheck} size="xs" fixedWidth />
                )}
              </span>

              <span className="flex flex-1 flex-col gap-px">
                <span className="text-sm font-medium leading-tight">
                  Auto Renewal
                </span>
                {/* {autoRenewalDisabled ? ( */}
                <span className="text-xs leading-tight text-c-subscriptions/70">
                  You can only have Auto Renewal enabled for one user currently.
                </span>
                {/* ) : null} */}
              </span>
            </label>
          </div>

          <div className="flex flex-row items-center gap-3">
            {/* HIVE SIGNER */}
            <div className="flex items-center justify-center w-full tbl:w-1/2 max-w-[250px] bg-pri/70 border border-pri dark:border-pri-d rounded-lg py-3 overflow-hidden hover:opacity-80 transition-opacity duration-150 cursor-pointer">
              <img
                src={hiveSigner}
                alt="HiveSigner"
                onClick={() => transfer("hivesigner")}
              />
            </div>

            {/* HIVE KEYCHAIN */}
            <div className="flex items-center justify-center w-full tbl:w-1/2 max-w-[250px] bg-black border border-pri dark:border-pri-d rounded-lg py-4 px-5 overflow-hidden hover:opacity-80 transition-opacity duration-150 cursor-pointer">
              <img
                src={keychain}
                alt="Hive Keychain"
                onClick={() => transfer("keychain")}
              />
            </div>
          </div>
          {/* <div
            onClick={() => setAutoRenewal(!autoRenewal)}
            className={classNames(
              "flex items-center gap-2 relative  justify-center gap-x-2 py-1 px-5 pl-4 h-fit min-h-[38px] min-w-[76px] rounded-full bg-c-subscriptions/20 text-c-subscriptions border border-c-subscriptions/30 text-xs font-semibold hover:bg-c-subscriptions/30 transition-colors duration-150 z-10 disabled:opacity-50 disabled:pointer-events-none",
              { "brightness-110": autoRenewal },
              { "brightness-75": !autoRenewal }
            )}
          >
            <label
              htmlFor="autoRenewal"
              className="cursor-pointer text-xs font-semibold text-c-subscriptions"
            >
              {autoRenewal ? "Disable" : "Enable"} Auto Renewal
            </label>
          </div> */}

          <span className="text-xs font-medium text-pri/50 dark:text-pri-d/50 uppercase">
            OR
          </span>

          <div className="flex flex-col items-center justify-center py-5 px-8 gap-y-6 rounded-xl border border-pri dark:border-pri-d">
            <strong className="font-semibold text-center">
              Manually send {price} HBD to @leosubscriptions
            </strong>
            <div className="flex flex-1 flex-row flex-wrap items-center justify-between w-full gap-x-4 gap-y-3">
              <div className="flex flex-1 flex-col gap-y-1">
                <label className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                  To:
                </label>
                <button
                  type="button"
                  className="flex justify-center items-center py-3 px-8 bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-lg text-sm font-medium hover:opacity-80 transition-opacity duration-150"
                  onClick={() => copyToClipboard("leosubscriptions")}
                >
                  @leosubscriptions
                </button>
              </div>

              <div className="flex flex-1 flex-col gap-y-1">
                <label className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                  Memo:
                </label>
                <button
                  type="button"
                  className="flex justify-center items-center py-3 px-8 bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-lg text-sm font-medium hover:opacity-80 transition-opacity duration-150"
                  onClick={() => copyToClipboard(`subscribe:${props.creator}`)}
                >
                  subscribe:{props.creator}
                </button>
              </div>

              <div className="flex flex-1 flex-col gap-y-1">
                <label className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                  Amount:
                </label>
                <button
                  type="button"
                  className="flex justify-center items-center py-3 px-8 bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-lg text-sm font-medium hover:opacity-80 transition-opacity duration-150"
                  onClick={() => copyToClipboard(String(price))}
                >
                  {price} HBD
                </button>
              </div>
            </div>
          </div>
        </div>

        <small className="pt-4 pb-2 text-center text-balance text-xs font-medium text-pri/50 dark:text-pri-d/50">
          Renew every month, Click and continue transfer with Keychain or
          Hivesigner.
        </small>
      </div>
    </motion.div>
  );
}

function SuccessScreen(props: SharedScreenProps) {
  return (
    <motion.div
      layout
      initial={{ y: 8, scale: 0.97, opacity: 0 }}
      animate={{ y: 0, scale: 1, opacity: 1 }}
      exit={{ y: 8, scale: 0.97, opacity: 0 }}
      transition={{ duration: 0.25 }}
      className="absolute w-full h-full flex flex-col"
    >
      <div className="flex flex-1 flex-col w-full items-center px-6 pt-16 gap-y-8">
        <div className="relative flex">
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="4x"
            fixedWidth
            className="text-green-500 drop-shadow-[0_0_16px_rgb(34_197_94)]"
          />
          <span className="absolute flex shrink-0 size-10 rounded-full border-4 border-bg dark:border-bg-d -right-2 -bottom-2 overflow-hidden">
            <SmallAvatar10
              author={props.creator}
              className="w-full h-full border-none outline-none"
            />
          </span>
        </div>

        <div className="flex flex-col justify-center items-center text-center gap-y-1">
          <strong className="text-lg">Subscribed to {props.creator}!</strong>
          <p className="text-sm text-pri/50 dark:text-pri-d/50">
            You have subscribed to {props.creator} and unlocked awesome
            features. You are able to see {props.creator}'s Subscriber-Only
            contents now. Also you got a shiny Subscriber Badge!
          </p>
          <button
            type="button"
            className="relative flex justify-center items-center gap-x-2 py-1 px-5 pl-4 mt-4 h-fit min-h-[38px] min-w-[76px] rounded-full bg-c-subscriptions/20 text-c-subscriptions border border-c-subscriptions/30 text-xs font-semibold hover:bg-c-subscriptions/30 transition-colors duration-150"
            onClick={() => props.setOpen(false)}
          >
            <FontAwesomeIcon icon={faCrown} size="sm" fixedWidth />
            Let's Explore!
          </button>
        </div>
      </div>
    </motion.div>
  );
}

