import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface PreviewToggleButtonProps {
  togglePreview: boolean;
  setTogglePreview: ((toggle: boolean) => boolean) | any;
}

const PreviewToggleButton = ({
  togglePreview,
  setTogglePreview
}: PreviewToggleButtonProps) => {
  return (
    <button
      onClick={() => setTogglePreview((toggle: boolean) => !toggle)}
      className="flex items-center justify-center px-4 py-1 gap-2 text-xs text-white rounded bg-zinc-400 dark:bg-zinc-700"
    >
      <FontAwesomeIcon
        icon={togglePreview ? faChevronUp : faChevronDown}
        width={10}
        height={10}
        style={{ marginTop: 2 }}
      />
      {togglePreview ? "Hide" : "Show"} Preview
    </button>
  );
};

export default PreviewToggleButton;
