import { Link } from "@remix-run/react";
import { CreatorSubCrown } from "../CreatorSub";
import { SmallAvatar6, SmallAvatar8 } from "../format/SmallAvatar";
import SubscribeCreatorButton from "./SubscribeCreatorButton";
import { cn } from "~/utils/cn";

export function SubscribeToCreator({
  creator,
  className
}: {
  creator: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "relative flex flex-col flex-1 w-full h-full justify-center items-center pt-8 pb-4 px-4",
        className
      )}
    >
      <CreatorSubCrown />
      <span className="max-w-[520px] text-balance text-lg text-pri/50 dark:text-pri-d/50 font-medium text-center pt-4">
        This is a Subscribers-Only blog post, subscribe to{" "}
        <Link
          to={`/profile/${creator}`}
          target="_blank"
          rel="noreferrer noopenner"
          className="inline-flex align-top px-1 gap-x-2 text-pri dark:text-pri-d no-underline hover:opacity-80 transition-opacity duration-150"
        >
          <SmallAvatar8 author={creator} /> <span>{creator || ""}</span>
        </Link>
        to see this content.
      </span>

      <div className="pt-7">
        <SubscribeCreatorButton creator={creator} />
      </div>
    </div>
  );
}

export function SubscribeToCreatorThread({
  creator,
  className
}: {
  creator: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "relative flex flex-row flex-wrap flex-1 w-full h-full justify-center items-center pt-8 pb-4 px-4 gap-x-6 gap-y-2",
        className
      )}
    >
      <CreatorSubCrown />
      <span className="max-w-[300px] text-center sm:text-left text-balance text-pri/50 dark:text-pri-d/50 text-base">
        This is a Subscribers-Only thread, subscribe to{" "}
        <Link
          to={`/profile/${creator}`}
          target="_blank"
          rel="noreferrer noopenner"
          className="inline-flex align-top px-1 gap-x-1.5 text-pri dark:text-pri-d no-underline hover:opacity-80 transition-opacity duration-150"
        >
          <SmallAvatar6 author={creator} /> <span>{creator || ""}</span>
        </Link>
        to see it.
      </span>

      {/* <div className="pt-7">
        <SubscribeCreatorButton creator={creator} />
      </div> */}
    </div>
  );
}

