import type { PollOptions } from "~/components/format/Polls";
import { useSlate } from "slate-react";
import { useEffect, useMemo, useState } from "react";
import * as React from "react";
// @ts-ignore
import debounce from "lodash.debounce";
import type { Descendant } from "slate";
import { Node } from "slate";
import {
  isImageLink,
  replaceDivAndCenterTagsWithAttributes,
  threadMarkdownProcessor
} from "~/utils/markdown";
import { ThreadText } from "~/components/threads/ThreadViewHelpers";
import { PollsPreview } from "~/components/format/PollsPreview";
import ImagesGrid from "../format/ImagesGrid";

interface PreviewProps {
  pollOptions: PollOptions;
  isPollCreated: boolean;
  editPoll: () => void;
  removePoll: () => void;
}

export function Preview({
  pollOptions,
  isPollCreated,
  editPoll,
  removePoll
}: PreviewProps) {
  const editor = useSlate();
  const [parsed, setParsed] = useState<null | React.ReactNode>(null);

  const setDebounced = useMemo(
    () =>
      debounce(
        (children: Descendant[]) => {
          let body = `⠀${children.map(n => Node.string(n))?.join(`\n`)}`;
          let result = threadMarkdownProcessor().processSync(
            replaceDivAndCenterTagsWithAttributes(
              `${body
                .replaceAll("⠀", "")
                .replace(/\n(https?:\/\/)/g, "$1")}` as any
            )
          ).result;

          if ((result as any)?.props?.children === undefined) {
            setParsed(null);
          } else {
            setParsed(result);
          }
        },
        250,
        { leading: true, maxWait: 1000 }
      ),
    [setParsed]
  );

  const IMAGE_REGEX =
    /(\bhttps:\/\/\S+)*.(jpg|jpeg|png|gif|bmp|webp|svg|tiff|ico)/g;

  const removeImgTags = (node: React.ReactNode): React.ReactNode => {
    if (React.isValidElement(node)) {
      if (
        node.type === "img" ||
        new RegExp(IMAGE_REGEX).test(node.props.href)
      ) {
        return null; // Skip <img> tags
      }
      // Recursively process children
      const children = React.Children.map(node.props.children, removeImgTags);
      return React.cloneElement(node, {}, children);
    }
    return node; // Return non-element nodes as is
  };

  useEffect(() => {
    setDebounced(editor.children);
  }, [editor?.children, setDebounced]);

  const links = useMemo(() => {
    return editor.children.flatMap(child => {
      return child?.children
        .filter(paragraph => typeof paragraph.text === "string")
        .flatMap(paragraph => {
          const urlRegex = /(https?:\/\/[^\s]+)/g;
          const matches = paragraph.text.match(urlRegex) || [];
          return matches.filter(isImageLink);
        });
    });
  }, [editor.children]);

  const bodyWithoutImages = removeImgTags(parsed);

  if (parsed === null && !isPollCreated) {
    return null;
  }

  const isPollsVisible =
    isPollCreated &&
    Object.keys(pollOptions).length > 0 &&
    !Object.values(pollOptions).every(val => !val);
  return (
    <div className="flex-1 shrink-0 p-2">
      {bodyWithoutImages && <ThreadText>{bodyWithoutImages}</ThreadText>}
      <ImagesGrid images={links} />
      {isPollsVisible && (
        <PollsPreview
          poll={pollOptions}
          editPoll={editPoll}
          removePoll={removePoll}
        />
      )}
    </div>
  );
}

