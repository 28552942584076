import React, { useEffect, useState } from "react";
import { Outlet, useLoaderData } from "@remix-run/react";
import { BackButton } from "~/components/InfoHeader";

import WalletSidebar, { WalletSmall } from "~/components/wallet/WalletSidebar";
import { WalletContext } from "~/contexts";
import { json, LoaderFunction } from "@remix-run/node";
import { cache } from "~/utils/cache";
import { TokenPrices } from "~/utils/coingecko";
import {
  PriceTicker,
  PriceTickerWrapper
} from "~/components/wallet/PriceTicker";
import {
  Balances,
  fetchBalance,
  fetchMetrics,
  fetchPendingUnstakes,
  HiveEngineFetchState,
  Metrics,
  PendingUnstakes,
  useHiveEngine
} from "~/utils/hiveengine";
import { fetchAccount, fetchAccountHistory, ParsedAccount } from "~/utils/hive";
import { SearchBarInitiator } from "~/components/SearchBarInitiator";
import { useAppStore } from "~/store";
import { isSSR } from "~/utils/ssr";

export const loader: LoaderFunction = async ({ params }) => {
  const accountName = params?.account?.replaceAll("@", "");

  const [
    leoToken,
    tokenPrices,
    account,
    dynamicGlobalProperties,
    hiveAccountHistory
  ] = await Promise.all([
    cache.getScotToken("LEO"),
    cache.getTokenPrices(),
    fetchAccount(accountName as string),
    cache.getDynamicGlobalProperties(),
    accountName ? fetchAccountHistory(accountName, -1, 50) : null
  ]);

  return json({
    leoToken,
    tokenPrices,
    account,
    dynamicGlobalProperties,
    hiveAccountHistory
  });
};

export const parseEngineTokenPrice = (
  metrics: Metrics,
  tokenName: string,
  hivePrice: object
) => {
  if (!metrics) return { priceChangePercent: `0%`, lastPrice: 0 };
  const token: Metrics = metrics?.find(
    (token: Metrics) => token.symbol === tokenName
  ) ?? { priceChangePercent: `0%`, lastPrice: 0 };
  return {
    usd: (hivePrice?.usd || 0) * Number(token.lastPrice),
    usd_24h_change: Number(token.priceChangePercent.slice(0, -1))
  };
};

export const parseEngineTokenBalance = (
  balance: Balances,
  tokenName: string
): Balances => {
  return (
    balance?.find((token: Metrics) => token.symbol === tokenName) ?? undefined
  );
};

export const parseEngineTokenUnstakes = (
  pendingUnstakes: PendingUnstakes[],
  tokenName: string
): PendingUnstakes => {
  return (
    pendingUnstakes?.find(
      (token: PendingUnstakes) => token.symbol === tokenName
    ) ?? ({ quantity: 0, quantityLeft: 0 } as unknown as PendingUnstakes)
  );
};

export interface WalletContextTypes {
  metrics: Metrics;
  balance: Balances;
  hivePrice: object;
  pendingUnstakes: PendingUnstakes;
  account: ParsedAccount;
  accountName: string;
  dynamicGlobalProperties: any;
  hiveAccountHistory: object;
}

interface WalletData {
  tokenPrices: TokenPrices;
  scotDenom: number;
  leoToken: object; // todo object
  account: ParsedAccount;
  dynamicGlobalProperties: any;
  hiveAccountHistory: object;
}

export default function Wallet() {
  const { account, tokenPrices, dynamicGlobalProperties, hiveAccountHistory } =
    useLoaderData() as WalletData;

  const [tokens, setTokens] = useState<string[] | undefined>(undefined);
  const setTokenPrices = useAppStore(store => store.wallet.setTokenPrices);

  useEffect(() => {
    setTokenPrices(tokenPrices);
  }, [tokenPrices, setTokenPrices]);

  const {
    hive_dollar: hbdPrice,
    hive: hivePrice,
    "wrapped-leo": wleoPrice
  } = tokenPrices;

  const [balance, balanceFetchState] = useHiveEngine({
    fetchFunction: fetchBalance,
    params: {
      account: account?.name || ""
    }
  });

  const [metrics] = useHiveEngine({
    fetchFunction: fetchMetrics,
    params: {
      tokens: tokens
    },
    deps: tokens
  });

  const [pendingUnstakes] = useHiveEngine({
    fetchFunction: fetchPendingUnstakes,
    params: {
      tokens: tokens,
      account: account?.name || ""
    },
    deps: tokens
  });

  const leoPrice = parseEngineTokenPrice(metrics, "LEO", hivePrice);

  useEffect(() => {
    if (typeof balance !== "object") return;

    const symbolsList =
      balance.length > 0
        ? balance.map((token: object) => token.symbol)
        : undefined;

    if (balanceFetchState === HiveEngineFetchState.Fetched) {
      setTokens(symbolsList);
    }
  }, [balance, balanceFetchState, setTokens]);

  const walletContext = {
    metrics: metrics || [],
    balance,
    hivePrice,
    pendingUnstakes: pendingUnstakes || [],
    account,
    accountName: account?.name || "",
    dynamicGlobalProperties,
    hiveAccountHistory
  };

  const isMobile = isSSR()
    ? false
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      );

  return (
    <React.Fragment>
      <main className="flex-1 w-full pc:flex-none pc:w-8/12 border-0 tbl:border-x border-pri dark:border-pri-d shrink-0">
        <WalletHeader>
          <div className="flex flex-row content-center">
            <BackButton />
            <WalletHeaderLabel>
              {account?.name || ""}`s Wallet
            </WalletHeaderLabel>
          </div>
        </WalletHeader>

        {isMobile && (
          <div className="flex flex-row gap-4 justify-between w-full px-6 pt-4 items-center">
            <WalletSmall
              name="LEO"
              description="BLOCKCHAIN WALLET"
              icon="leo"
              path={`/${account?.name || ""}/wallet/leo`}
            />
            <WalletSmall
              name="HIVE"
              description="BLOCKCHAIN WALLET"
              icon="hive"
              path={`/${account?.name || ""}/wallet/hive`}
            />
            <WalletSmall
              name="HIVE ENGINE"
              description="TOKENS"
              icon="hive-engine"
              path={`/${account?.name || ""}/wallet/hive-engine`}
            />
          </div>
        )}

        <WalletBody>
          <WalletContext.Provider value={walletContext}>
            <PriceTickerWrapper>
              <PriceTicker
                token="LEO"
                price={leoPrice?.usd || 0.07}
                change={leoPrice?.usd_24h_change}
              />
              <PriceTicker
                token="HIVE"
                price={hivePrice?.usd || 0.3}
                change={hivePrice?.usd_24h_change}
              />
              <PriceTicker
                token="HBD"
                price={hbdPrice?.usd || 1}
                change={hbdPrice?.usd_24h_change}
              />
            </PriceTickerWrapper>
            <Outlet />
          </WalletContext.Provider>
        </WalletBody>
      </main>

      <aside className="hidden tbl:block w-80 pt-[48px] pc:pt-0">
        <div className="sticky top-0 flex flex-col gap-y-5 p-3">
          <SearchBarInitiator />
          <WalletSidebar account={account} />
        </div>
      </aside>
    </React.Fragment>
  );
}

interface WalletProviderProps {
  children: React.ReactNode;
}

export function WalletHeader({ children }: WalletProviderProps) {
  return (
    <div className="flex flex-col h-12 pl-2 mt-[48px] sm:mt-0 font-large bg-pri/[0.6] dark:bg-pri-d/[0.8] backdrop-blur-lg first-letter border-b border-pri dark:border-pri-d sticky top-0 z-50">
      {children}
    </div>
  );
}

export function WalletHeaderLabel({ children }: WalletProviderProps) {
  return <h1 className="flex items-center pl-2 font-bold">{children}</h1>;
}

export function WalletBody({ children }: WalletProviderProps) {
  return <div className="m-5 p-1">{children}</div>;
}

interface WalletSectionProps {
  title: string;
  icon?: string;
  children: React.ReactNode;
}

export function WalletSection({ title, icon, children }: WalletSectionProps) {
  return (
    <div className="flex flex-col py-[36px]">
      <div className="flex flex-row items-center gap-3 pb-3">
        <h2 className="font-bold text-lg">{title}</h2>
        {icon && (
          <React.Fragment>
            <strong>—</strong>
            <div className="flex pt-[1px]">
              <img src={icon} alt="" width="auto" height={16} />
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="flex flex-col mt-2">{children}</div>
    </div>
  );
}
