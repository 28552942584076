import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import type {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  DragEventHandler,
  RefObject,
  SetStateAction
} from "react";

interface Props {
  setOpen: (open: boolean) => void;
  handleImageDrag: DragEventHandler<HTMLDivElement>;
  handleDrop: DragEventHandler<HTMLDivElement>;
  handleChange: ChangeEventHandler<HTMLDivElement>;
  imageInputRef: RefObject<HTMLInputElement>;
  fileUploadLogo: string;
  dragActive: boolean;
  fileDropped: File | null;
  setImageUrl: Dispatch<SetStateAction<string | boolean>>;
  imageUrl: string | boolean;
  handleImageSubmit: () => void;
  fileUploading: boolean;
}

export default function ImageSelector({
  dragActive,
  fileDropped,
  fileUploadLogo,
  handleDrop,
  handleChange,
  handleImageDrag,
  handleImageSubmit,
  imageInputRef,
  setImageUrl,
  imageUrl,
  fileUploading,
  setOpen
}: Props) {
  return (
    <FloatingHTMLOverlay
      onClick={ev => ev.stopPropagation()}
      className="bg-overlay z-[19000] flex justify-center py-[10vh]"
      lockScroll
    >
      <div className="relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full">
        <div
          onClick={() => setOpen(false)}
          className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <div
          onDragEnter={handleImageDrag}
          onDragLeave={handleImageDrag}
          onDragOver={handleImageDrag}
          onDrop={handleDrop}
          onChange={handleChange}
          className={classNames(
            "mt-12 py-6 px-12 text-sm flex justify-center text-center flex-col self-center w-[100%] min-h-[12rem] border-dashed border-2 dark:border-gray-500/[.25] dark:hover:border-gray-500/[.65] rounded-2xl duration-75",
            { "bg-acc": dragActive && !fileDropped?.[0] }
          )}
        >
          <label className="flex flex-1 flex-col justify-center align-center cursor-pointer">
            <input
              ref={imageInputRef}
              className="hidden"
              id="input-file-upload"
              type="file"
              accept="image/*"
              multiple
            />
            <div
              className={classNames("w-[64px] h-[64px] mb-4 mx-auto", {
                "!w-[128px] !h-[128px]": fileDropped?.[0]
              })}
            >
              <img
                className="object-contain w-full h-full rounded-lg"
                src={
                  fileDropped?.[0]
                    ? URL.createObjectURL(fileDropped?.[0])
                    : fileUploadLogo
                }
                alt=""
              />
              {/* <FontAwesomeIcon icon={faUpload}/> */}
            </div>
            {fileDropped?.[0] ? (
              <span>
                Press Submit to upload{" "}
                <b className="text-acc bold">
                  {Array.from(fileDropped)
                    .map(file => file?.[0]?.name)
                    .join(", ")}
                </b>{" "}
                to InLeo!
              </span>
            ) : (
              <span>
                <b>Browse files</b> , or simply <b>drag</b> here.
              </span>
            )}
          </label>
        </div>
        <div className="text-sm text-gray-400 font-bold self-center">OR</div>
        <div className="flex flex-col gap-y-0.5 self-center w-full">
          <span className="text-md font-semibold text-gray-400 pb-2">
            Paste your link
          </span>
          <input
            className="border border-pri dark:border-pri-d rounded-lg py-2 px-4 text-sm outline-none bg-transparent w-full"
            placeholder="Image URL"
            type="text"
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setImageUrl(ev.target.value)
            }
          />
        </div>
        <div className="flex flex-row justify-center">
          <button
            disabled={fileUploading || (!fileDropped?.[0] && !imageUrl)}
            onClick={handleImageSubmit}
            className="flex justify-center items-center px-4 py-2 text-sm text-white font-semibold rounded bg-acc hover:bg-acc-hov disabled:cursor-not-allowed"
          >
            {fileUploading ? (
              <>
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Uploading..
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </FloatingHTMLOverlay>
  );
}
