import { useState } from "react";
import { Thread } from "~/utils/leocache";
import { SmallAvatar8 } from "../format/SmallAvatar";
import LoadOnlyReply from "./ThreadViewHelpers";

interface LoadMoreThread {
  author: string;
  permlink: string;
  firstReplyAuthor: string;
  children: number;
  replies: string[];
}

export function LoadMoreThread({
  author,
  permlink,
  firstReplyAuthor,
  children,
  replies
}: LoadMoreThread) {
  const [unfolded, setUnfolded] = useState(false);

  const loadMoreClick = (event: React.MouseEvent) => {
    setUnfolded(cur => !cur);
    event?.stopPropagation();
    event?.preventDefault();
  };

  if (unfolded) {
    return (
      <>
        {replies.map((authorPerm: string, i: number) => {
          const [author, permlink] = authorPerm.split("/");
          return (
            <LoadOnlyReply
              key={authorPerm}
              continues={i !== replies.length - 1}
              author={author}
              permlink={permlink}
              notop={false}
            />
          );
        })}
        <div
          onClick={loadMoreClick}
          className="
          p-2 pt-2 pl-5 relative pm-8 flex flex-col mt-1 cursor-pointer
        sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d transition-colors"
        >
          <div className="flex flex-row justify-center align-middle font-semibold hover:text-acc">
            Collapse @{author}'s thread
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      onClick={loadMoreClick}
      className="
      p-2 pt-2 pl-5 relative pm-8 flex flex-col mt-1 cursor-pointer
        border-b border-pri dark:border-pri-d
       sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d transition-colors"
    >
      <div
        className="w-[1px]
          sm:left-[39.5px] md:left-[39.5px] left-[34.5px] pc:left-[39.5px]
          bottom-8
          -z-10 h-10 absolute bg-zinc-300 dark:bg-zinc-700 rounded-full "
      />
      <div className="flex flex-row pl-1">
        <SmallAvatar8 author={firstReplyAuthor} />
        <span className="pl-5 self-center text-sm text-acc">
          See {replies.length} other {children === 1 ? "reply" : "replies"}
        </span>
      </div>
    </div>
  );
}
