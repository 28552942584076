import { ClientOnly } from "remix-utils/client-only";

export default function ExternalFrame({ src }: { src: string }) {
  console.log(src);
  if (!src?.startsWith("https://")) return <p>{src}</p>;
  return (
    <ClientOnly>
      {() => (
        <span className="relative block w-full h-0 pb-[56.25%] rounded-xl overflow-hidden">
          <iframe
            src={src}
            loading="lazy"
            width="100%"
            height="100%"
            frameBorder="0"
            className="absolute w-full max-w-full h-full overflow-hidden"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </span>
      )}
    </ClientOnly>
  );
}

