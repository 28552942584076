import { Link, useLocation } from "@remix-run/react";
import classNames from "classnames";
import { ParsedAccount } from "~/utils/hive";

interface WalletSidebar {
  account: ParsedAccount;
}
export default function WalletSidebar({ account }: WalletSidebar) {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col gap-3">
        <Wallet
          name="LEO"
          description="BLOCKCHAIN WALLET"
          icon="leo"
          path={`/${account?.name || ""}/wallet/leo`}
        />
        <Wallet
          name="HIVE"
          description="BLOCKCHAIN WALLET"
          icon="hive"
          path={`/${account?.name || ""}/wallet/hive`}
        />
        <Wallet
          name="HIVE ENGINE"
          description="TOKENS"
          icon="hive-engine"
          path={`/${account?.name || ""}/wallet/hive-engine`}
        />
      </div>
    </div>
  );
}

interface WalletProps {
  name: string;
  description: string;
  path: string;
  icon: "leo" | "hive" | "hive-engine";
}

export function Wallet({ name, description, path, icon }: WalletProps) {
  const { pathname } = useLocation();

  const isWalletActive = pathname === path;

  return (
    <Link to={path}>
      <div
        className={classNames(
          "flex flex-1 items-center p-4 py-5 gap-4 border-2 border-pri dark:border-pri-d rounded-lg opacity-[.75] text-zinc-500 hover:bg-zinc-700/[.25] transition-colors duration-150",
          {
            "opacity-[1] !text-acc !border-acc dark:border-acc bg-acc/[.1] hover:bg-acc/[.1]":
              isWalletActive
          }
        )}
      >
        <div
          className={classNames("flex w-[36px] h-[36px] text-inherit", {
            "text-red-600": isWalletActive
          })}
        >
          <WalletIcon icon={icon} />
        </div>
        <div className="flex flex-col flex-1 gap-[2px]">
          <strong
            className={classNames("font-bold", {
              "text-pri/60 dark:text-pri-d/60": !isWalletActive,
              "text-pri dark:text-pri-d": isWalletActive
            })}
          >
            {name}
          </strong>
          <p
            className={classNames("font-semibold text-xs text-zinc-500", {
              "text-pri dark:text-pri-d": isWalletActive
            })}
          >
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
}

export function WalletSmall({ name, description, path, icon }: WalletProps) {
  const { pathname } = useLocation();

  const isWalletActive = pathname === path;

  return (
    <Link className="w-full flex-1" to={path}>
      <div
        className={classNames(
          "flex flex-col justify-center w-full flex-1 items-center p-2 py-3 gap-2 border-2 border-pri dark:border-pri-d rounded-lg opacity-[.75] text-zinc-500 hover:bg-zinc-700/[.25] transition-colors duration-150",
          {
            "opacity-[1] !text-acc !border-acc dark:border-acc bg-acc/[.1] hover:bg-acc/[.1]":
              isWalletActive
          }
        )}
      >
        <div
          className={classNames("flex w-[36px] h-[36px] text-inherit", {
            "text-red-600": isWalletActive
          })}
        >
          <WalletIcon icon={icon} />
        </div>
        <div className="flex flex-col flex-1 gap-[2px]">
          <strong
            className={classNames("font-bold", {
              "text-pri/60 dark:text-pri-d/60": !isWalletActive,
              "text-pri dark:text-pri-d": isWalletActive
            })}
          >
            {name}
          </strong>
        </div>
      </div>
    </Link>
  );
}

interface WalletIconProps {
  icon: WalletProps["icon"];
  height?: string;
  width?: string;
}

export function WalletIcon({ icon, height, width }: WalletIconProps) {
  if (icon === "leo") {
    return (
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        height={height}
        width={width}
      >
        <defs>
          <style>
            {`
      .cls-1 {
        fill: currentColor;
      }
        `}
          </style>
        </defs>
        <polygon
          className="cls-1"
          points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
        />
        <polygon
          className="cls-1"
          points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
        />
      </svg>
    );
  }

  if (icon === "hive") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 220 190"
        height={height}
        width={width}
      >
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M157.27,107.26a1,1,0,0,1,.82,1.42l-46.75,80.85a1,1,0,0,1-.82.47H81.94a.94.94,0,0,1-.81-1.42l46.75-80.85a.94.94,0,0,1,.81-.47ZM129.48,84.09a1,1,0,0,1-.82-.47L81.13,1.42A.94.94,0,0,1,81.94,0h28.58a1,1,0,0,1,.82.47l47.53,82.2a.94.94,0,0,1-.81,1.42Z"
              fill="currentColor"
            />
            <path
              d="M135.13,1.42A.94.94,0,0,1,136,0h28.62a.93.93,0,0,1,.81.47l54.49,94.06a.93.93,0,0,1,0,.94l-54.49,94.06a.93.93,0,0,1-.81.47H136a.94.94,0,0,1-.82-1.42L189.34,95Zm-23.26,93.1a1,1,0,0,1,0,1L57.13,189.53a1,1,0,0,1-1.65,0L.13,95.48a1,1,0,0,1,0-1L54.87.47a1,1,0,0,1,1.65,0Z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (icon === "hive-engine") {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 280.000000 280.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)"
          fill="currentColor"
          stroke="none"
        >
          <path
            d="M1240 2673 c0 -37 -22 -93 -47 -123 -79 -94 -237 -94 -316 0 -14 16
          -31 30 -38 30 -14 0 -196 -94 -228 -119 -24 -18 -24 -19 -8 -60 21 -52 21 -91
          2 -139 -42 -99 -146 -148 -250 -117 -31 8 -60 13 -66 9 -16 -9 -101 -142 -123
          -192 l-19 -42 45 -39 c67 -59 85 -138 49 -216 -24 -55 -71 -91 -139 -111 -31
          -9 -60 -22 -64 -28 -9 -13 -10 -222 -2 -253 5 -19 13 -23 43 -23 46 0 123 -47
          150 -91 50 -81 35 -177 -37 -240 l-46 -40 23 -47 c20 -42 120 -202 126 -202 1
          0 19 7 38 15 111 46 239 -10 274 -121 17 -55 16 -66 -13 -156 -4 -12 15 -28
          78 -66 46 -27 105 -58 130 -69 l45 -21 27 34 c78 98 239 100 319 4 25 -30 47
          -86 47 -122 0 -16 14 -18 155 -18 l155 0 0 35 c0 61 45 123 117 159 39 20 122
          21 166 2 35 -14 91 -63 98 -85 4 -11 32 0 115 43 60 32 120 65 133 75 22 17
          22 19 7 55 -42 101 4 208 106 250 46 20 118 20 162 2 34 -14 34 -14 63 22 34
          42 123 196 123 212 0 5 -4 10 -9 10 -17 0 -71 61 -86 96 -49 119 44 264 170
          264 l35 0 0 143 0 144 -56 12 c-180 41 -221 251 -69 356 20 13 19 15 -14 77
          -45 82 -111 178 -124 178 -6 0 -33 -7 -62 -16 -47 -15 -56 -15 -107 0 -41 12
          -65 27 -96 60 -51 55 -63 114 -39 190 9 27 15 51 14 51 -64 46 -232 135 -255
          135 -6 0 -17 -11 -26 -25 -9 -13 -36 -36 -61 -50 -72 -43 -170 -35 -236 17
          -36 29 -69 91 -69 129 l0 39 -155 0 c-141 0 -155 -2 -155 -17z m339 -318 c290
          -55 536 -216 686 -450 85 -132 127 -252 145 -416 30 -275 -73 -556 -279 -759
          -197 -195 -455 -300 -736 -300 -280 0 -532 100 -728 289 -237 228 -341 550
          -278 856 61 293 239 528 511 673 199 107 461 148 679 107z"
          />
          <path
            d="M1235 2185 c-157 -34 -281 -98 -398 -205 -114 -104 -189 -237 -233
          -409 -26 -103 -16 -310 21 -419 36 -108 111 -232 188 -310 63 -65 184 -152
          208 -152 8 1 46 49 85 108 38 59 118 177 176 263 l106 155 -81 60 c-45 32
          -108 79 -142 104 -33 25 -70 52 -81 60 -12 8 -58 43 -103 76 l-82 62 48 34
          c26 19 147 102 268 184 409 275 480 325 483 334 3 9 -94 40 -186 59 -70 14
          -202 13 -277 -4z"
          />
          <path
            d="M1665 1932 c-55 -81 -135 -198 -178 -260 -77 -112 -78 -115 -57 -129
          11 -8 47 -36 79 -61 32 -26 131 -101 220 -167 88 -66 161 -123 161 -126 0 -4
          -62 -48 -137 -99 -76 -51 -185 -125 -243 -165 -58 -40 -170 -116 -250 -169
          -80 -54 -151 -104 -159 -111 -11 -11 -2 -17 57 -34 116 -35 182 -44 282 -38
          120 7 203 29 308 80 261 126 429 372 448 657 19 283 -93 530 -321 706 -35 27
          -98 65 -107 64 -2 -1 -48 -67 -103 -148z"
          />
        </g>
      </svg>
    );
  }

  return null;
}
