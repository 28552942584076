import * as React from "react";
import type { Draft } from "immer";
import produce from "immer";

export function useExtendArrayState<T>(
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  return React.useCallback(
    (slice: T[]) =>
      setState(
        produce(draft => {
          draft.push(...(slice as Draft<T>[]));
        })
      ),
    [setState]
  );
}

export function useReplaceArrayState<T>(
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  return React.useCallback(
    (index: number, slice: T[]) =>
      setState(
        produce(draft => {
          draft.splice(index + 1, slice.length, ...(slice as Draft<T>[]));
        })
      ),
    [setState]
  );
}

export function usePushArrayState<T>(
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  return React.useCallback(
    (item: T) =>
      setState(
        produce(draft => {
          draft.push(item as Draft<T>);
        })
      ),
    [setState]
  );
}

export function usePushStartArrayState<T>(
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  return React.useCallback(
    (item: T) =>
      setState(
        produce(draft => {
          draft.unshift(item as Draft<T>);
        })
      ),
    [setState]
  );
}

export function useEditIndexArrayState<T>(
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  return React.useCallback(
    (item: any, index?: number) =>
      setState(
        produce(draft => {
          draft[index ?? 0] = item
        })
      ),
    [setState]
  );
}
