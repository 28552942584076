import { PollOptions } from "./Polls";
import { useAppStore } from "~/store";

export function PollsPreview({
  poll,
  editPoll,
  removePoll
}: {
  poll?: PollOptions;
  editPoll?: () => void;
  removePoll?: () => void;
}) {
  const pollContext = useAppStore(store => store.utilities.poll);

  if (
    !poll &&
    (pollContext === null ||
      Object.values(pollContext?.pollOptions)?.every(v => !v) ||
      pollContext?.insertedToEditor)
  )
    return <></>;

  const data = poll || pollContext?.pollOptions || {};

  return (
    <div className="flex flex-1 flex-col max-w-[420px] my-2 mx-auto gap-y-2 p-1.5 rounded-2xl border border-pri dark:border-pri-d">
      {Object.values(data).map((opt, index) =>
        opt ? (
          <button
            key={index}
            type="button"
            className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
          >
            {opt}
          </button>
        ) : null
      )}

      <div className="flex flex-1 justify-between items-center gap-x-4 px-1 text-pri/50 dark:text-pri-d/50">
        <small className="text-xs">0 votes</small>

        <div className="flex items-center gap-x-1.5">
          <button
            type="button"
            className="flex text-xs text-pri/50 dark:text-pri-d/50"
            onClick={() => editPoll?.() || pollContext?.editPoll?.()}
            disabled={!editPoll && !pollContext?.editPoll}
          >
            Edit
          </button>

          <span className="text-xs font-bold">·</span>

          <button
            type="button"
            className="flex text-xs text-red-500 font-semibold"
            onClick={() => removePoll?.() || pollContext?.removePoll?.()}
            disabled={!removePoll && !pollContext?.removePoll}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}
