export function LoadingIndicator() {
  return (
    <div className="motion-safe:animate-pulse flex flex-col gap-2.5 pt-4">
      <div className="grid gap-2.5 grid-cols-3">
        <div className="h-3.5 bg-gray-200 dark:bg-zinc-800 rounded col-span-1" />
        <div className="h-3.5 bg-gray-200 dark:bg-zinc-800 rounded col-span-2" />
      </div>
      <div className="h-3.5 bg-gray-200 dark:bg-zinc-800 rounded" />
      <div className="grid gap-5 grid-cols-3">
        <div className="h-3.5 bg-gray-200 dark:bg-zinc-800 rounded col-span-2" />
      </div>
    </div>
  );
}

