export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");

    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration);
    };

    videoElement.onerror = error => {
      reject(error);
    };

    const objectUrl = URL.createObjectURL(file);
    videoElement.src = objectUrl;

    // Clean up the object URL to free memory
    videoElement.onloadeddata = () => {
      URL.revokeObjectURL(objectUrl);
    };
  });
};
