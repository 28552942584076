import { HiveContent, ParsedAccount } from "./hive";
import { isSSR } from "./ssr";

export function nullDefault<T>(nullable: T | null): Partial<T> {
  return nullable || ({} as Partial<T>);
}

export function sliceString(value: string, limit: number): string {
  if (value?.length > limit) {
    const slicedString = value?.slice(0, limit)?.trimEnd();
    const dots = slicedString?.endsWith?.("\n") ? " " : "...";
    return `${slicedString}${dots}`;
  }
  return value;
}

export function getImagesFromPostBody(post: HiveContent) {
  return post.body.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g);
}

export const getPostingJsonMetadata = (account: ParsedAccount) => {
  if (
    typeof account?.posting_json_metadata === "string" &&
    account?.posting_json_metadata
  ) {
    return JSON.parse(account.posting_json_metadata);
  } else {
    return account?.posting_json_metadata;
  }
};
export function parseMarkdown(value: string): string[] {
  const lines = value?.split("\n");

  return lines?.filter(line => line !== "")?.map(line => line);
}

export function decodeHtmlEntities(html: string): string {
  if (isSSR()) return "";

  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
