export const SEARCH_SETTINGS = [
  "Threads of account",
  "Blog of account",
  "Threads",
  "Glossary",
  "Docs",
  "Accounts",
  "Tags"
];

export const searchTypePlaceHolders = [
  ["Username to search threads of", "Search any threads"],
  ["Username to search posts of", "Search any posts"],
  ["Search any threads"],
  ["Search any glossary term"],
  ["Search anything in docs"],
  ["Search a hive user"],
  ["Search tag"]
];

