import { useLocation } from "@remix-run/react";
import { useCallback, useEffect } from "react";

export function useOnRouteChange(cb: () => void) {
  const { pathname } = useLocation();

  const onRouteChangeCallback = useCallback(() => {
    cb();
  }, []);

  // listen for route change
  useEffect(() => {
    onRouteChangeCallback();
  }, [pathname]);
}
