import { parseEngineTokenBalance } from "~/routes/$account.wallet";
import { useAppStore } from "~/store";
import { fetchBalance, useHiveEngine } from "~/utils/hiveengine";

import Cub from "../images/Cub.svg";
import Apex from "../images/Apex.svg";
import Leader from "../images/Leader.svg";
import Newborn from "../images/Newborn.svg";
import Lion from "../images/Lion.svg";

interface LeoPowerBadge {
  accountName: string;
}

export default function LeoPowerBadge({ accountName }: LeoPowerBadge) {
  const prices = useAppStore(store => store.wallet.tokenPrices);

  const [accountBalance] = useHiveEngine({
    fetchFunction: fetchBalance,
    params: {
      account: accountName
    }
  });

  const leoBalance = parseEngineTokenBalance(accountBalance as any, "LEO");

  const totalStake = +leoBalance?.delegationsOut + +leoBalance?.stake;

  const calculateLevel = () => {
    if (!balance) return;

    if (+totalStake < 4999) return "Newborn Cub";
    if (+totalStake > 149_999) return "Apex Lion";
    if (+totalStake > 49_999) return "Leader of The Pack";
    if (+totalStake > 14_999) return "Lion";
    if (+totalStake > 4999) return "Cub";
  };

  const getBadge = (level: string | undefined) => {
    if (!level) return;

    if (level === "Newborn Cub") return Newborn;
    if (level === "Apex Lion") return Apex;
    if (level === "Leader of The Pack") return Leader;
    if (level === "Lion") return Lion;
    if (level === "Cub") return Cub;
  };

  const formatBalance = (value: number | undefined) => {
    if (!value) return value;

    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2
    });

    return formatter.format(value);
  };

  const staked = +totalStake;
  const balance = Number(leoBalance?.balance || 0);
  const leoPrice = prices ? Number(prices["wrapped-leo"]?.usd || 0) : 0;

  const totalBalance = formatBalance(Number((staked + balance) * leoPrice));

  if (!prices || !leoBalance || !accountBalance) return <LeoPowerSkeleton />;

  return (
    <div className="flex flex-row w-full pt-4 border-t border-pri/50 dark:border-pri-d/50">
      <div className="flex flex-wrap bg-gray-200 dark:bg-zinc-800 w-14 h-14 rounded-full overflow-hidden">
        {getBadge(calculateLevel()) !== "undefined" ? (
          <img
            className="h-full w-full object-cover"
            src={getBadge(calculateLevel())}
            alt="level"
            loading="lazy"
          />
        ) : null}
      </div>
      <div className="flex flex-row flex-1 justify-between">
        <div className="flex flex-col pl-4">
          <span className="text-base font-bold text-pri dark:text-pri-d mb-1">
            {calculateLevel()}
          </span>
          <span className="text-sm font-medium text-pri/60 dark:text-pri-d/60 leading-5">
            <span className="font-bold text-pri dark:text-pri-d">
              {+staked}
            </span>{" "}
            LEO Power
          </span>
          <span className="text-sm font-medium text-pri/60 dark:text-pri-d/60 leading-5">
            <span className="font-bold text-pri dark:text-pri-d">
              {+leoBalance?.balance}
            </span>{" "}
            LEO
          </span>
        </div>
        <div className="flex flex-col items-end">
          <span className="font-medium text-pri/60 dark:text-pri-d/60">
            Estimated Account Value
          </span>

          <span className="w-fit text-sm font-semibold py-1 px-3 rounded-full bg-acc/[.15] text-acc">
            {totalBalance}$
          </span>
        </div>
      </div>
    </div>
  );
}

function LeoPowerSkeleton() {
  return (
    <div className="flex flex-row w-full pt-5 border-t border-pri/50 dark:border-pri-d/50 animate-pulse">
      <div className="flex flex-wrap bg-gray-200 dark:bg-zinc-700 w-14 h-14 rounded-full" />

      <div className="flex flex-row flex-1 justify-between">
        <div className="flex flex-col pl-4">
          <span className="w-28 h-4 rounded-lg bg-gray-200 dark:bg-zinc-700 mb-2" />

          <span className="w-20 h-3 rounded-lg bg-gray-200 dark:bg-zinc-700 mb-1" />
          <span className="w-20 h-3 rounded-lg bg-gray-200 dark:bg-zinc-700" />
        </div>

        <div className="flex flex-col items-end">
          <span className="w-24 h-3 rounded-lg bg-gray-200 dark:bg-zinc-700 mb-2" />

          <span className="w-14 h-4 rounded-lg bg-gray-200 dark:bg-zinc-700" />
        </div>
      </div>
    </div>
  );
}
