import { useEffect, useMemo, useState } from "react";
import { useAppStore } from "~/store";
import { prepareAuthorization } from "~/utils/infra";
import { decryptPostBody, leocache, type Thread } from "~/utils/leocache";
import { buildLinksImagesArray } from "~/utils/markdown";

export function useDecryption(
  content: Thread | null | undefined,
  encrypted: boolean,
  removeImages: boolean
) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [decryptedBody, setDecryptedBody] = useState(content?.body);
  const [decrypting, setDecrypting] = useState(false);
  const [decryptionFailed, setDecryptionFailed] = useState(false);

  useEffect(() => {
    if (!content) return;

    if (!decryptedBody && content.body) {
      setDecryptedBody(content.body);
    }
  }, [content]);

  useEffect(() => {
    if (!activeAccount) return;
    if (!encrypted) return;
    if (!content || !content.body.startsWith("#")) return;

    void (async function () {
      setDecrypting(true);

      try {
        const authorization = await prepareAuthorization(activeAccount);
        const decrypted_body = await decryptPostBody(
          authorization,
          content.author,
          content.body
        );

        if (!decrypted_body) {
          setDecrypting(false);
          setDecryptionFailed(true);
        } else {
          setDecryptionFailed(false);
          setDecryptedBody(decrypted_body);
        }
      } catch {
        setDecryptionFailed(true);
      }

      setDecrypting(false);
    })();
  }, [encrypted, content, activeAccount]);

  const images = useMemo(() => {
    // if (!decryptedBody || !encrypted) return [];

    let imagesFromMetadata = content?.metadata?.images || [];

    // if (metadata.encrypted) {
    //   imagesFromMetadata = buildLinksImagesArray(decryptedBody)?.images || [];
    // }

    const _ = buildLinksImagesArray(decryptedBody ?? "")?.images || [];

    const modifyImageUrls = (url: string) => {
      return url.replaceAll(")", "").replaceAll("(", "");
    };

    const modified = imagesFromMetadata.concat(_).map(modifyImageUrls);
    return modified;
  }, [content, encrypted, decryptedBody]);

  const IMAGE_REGEX =
    /(https?:\/\/\S+\.(?:jpg|jpeg|png|gif|bmp|webp|svg|ico))/gi;
  const bodyWithoutImages = decryptedBody?.replace(IMAGE_REGEX, "");

  return {
    decryptedBody: removeImages ? bodyWithoutImages : decryptedBody,
    decrypting,
    decryptionFailed,
    images
  };
}

export function usePostDecryption(
  content: string,
  creator?: string,
  encrypted?: boolean
) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [decryptedBody, setDecryptedBody] = useState(content);
  const [decrypting, setDecrypting] = useState(false);
  const [decryptionFailed, setDecryptionFailed] = useState(false);

  useEffect(() => {
    if (!activeAccount) return;
    if (!encrypted) return;
    if (!decryptedBody?.startsWith("#")) return;

    void (async function () {
      setDecrypting(true);

      try {
        const authorization = await prepareAuthorization(activeAccount);
        const decrypted_body = await decryptPostBody(
          authorization,
          creator,
          content
        );

        if (!decrypted_body) {
          setDecrypting(false);
          setDecryptionFailed(true);
        } else {
          setDecryptionFailed(false);
          setDecryptedBody(decrypted_body);
        }
      } catch {
        setDecryptionFailed(true);
      }

      setDecrypting(false);
    })();
  }, [encrypted, content, creator, activeAccount]);

  return {
    decryptedBody,
    decrypting,
    decryptionFailed
  };
}

export function useCanComment(author?: string, encrypted?: boolean) {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const [canComment, setCanComment] = useState(true);

  useEffect(() => {
    void (async function () {
      if (!activeAccount || !author || !encrypted) return;

      if (encrypted) {
        const subscriptions: string[] = await leocache.getCreatorSubscriptions(
          activeAccount.name
        );

        if (author === activeAccount.name || subscriptions.includes(author)) {
          setCanComment(true);
        } else {
          setCanComment(false);
        }
      }
    })();
  }, [activeAccount, author, encrypted]);

  return canComment;
}
