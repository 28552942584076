import {
  CustomElement,
  MarkdownEditorState
} from "~/components/MarkdownEditor/types";
import { getRandomPlaceholder } from "~/components/MarkdownEditor/utils";

export const initialValue: CustomElement[] = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

export enum MarkdownEditorTypes {
  Post,
  Thread,
  Comment
}

export const placeHolderList = {
  [MarkdownEditorTypes.Thread]: [
    "One more Thread, just one more...",
    "A thread a day to keep the bears away",
    "No Threads, No Gains",
    "Type words here, right HERE",
    "Use the Force, Lion!",
    "Itchy fingers? Thread about it!",
    "Make words not war!",
    "A keyboard is for typing!",
    "Start roaring...",
    "Stop staring at the keyboard",
    "Epic thread incoming...",
    "Life's too short to not thread boldly",
    "Don't just follow the thread, create your own",
    "Every time you Thread a puppy gets adopted",
    "Keep calm and thread on",
    "Got #memes? Share 'em",
    "Ready to go viral?",
    "Is that the best you can do?",
    "Brand yourself with threads",
    "Send a thread, it's free"
  ],
  [MarkdownEditorTypes.Post]: [
    "Your ideas...",
    "Your creativity...",
    "Put here your thoughts..."
  ],
  [MarkdownEditorTypes.Comment]: ["Your opinions..."]
};

export const initialMarkdownEditorState: Omit<
  MarkdownEditorState,
  "placeholder"
> = {
  togglePreview: true,
  editorMounted: false,
  imageUrl: false,
  dragActive: false,
  fileDropped: null,
  fileUploading: false,
  open: false,
  showInfo: true,
  containsLink: false,
  pollsOpen: false,
  pollOptions: { 0: "", 1: "" },
  pollCreated: false,

  mentionSuggestionActive: false,
  mentionWritten: false,
  accountLookupResults: [],
  caretCoordinates: false
};
