import {
  FloatingPortal,
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from "@floating-ui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { NavItemLink } from "~/root";
import { faAward, faCog } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import { isSSR } from "~/utils/ssr";
import { toast } from "react-toastify";
import { clearBookmarks } from "~/utils/leocache";
import { useAppStore } from "~/store";

export default function BookmarksDropdown({
  children,
  onFocusChange
}: {
  children: React.ReactNode;
  onFocusChange: (focused: boolean) => void;
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [offset(5), flip()],
    placement: "bottom-end",
    strategy: "fixed"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss
  ]);

  useEffect(() => {
    onFocusChange(open);
  }, [open]);

  useOnRouteChange(() => {
    setOpen(false);
  });

  const [{ activeAccount, keys }, isDarkMode] = useAppStore(store => [
    store.account,
    store.settings.dark
  ]);
  // const { mutate } = useContext(BookmarkContext);
  const { signature, publicKey, proxy } = keys;
  const [signatureFromStorage] = useState(
    !isSSR() && window.localStorage.getItem("activeAccount")
  );

  const removeAllBookmarks = async () => {
    if (!activeAccount || (!signature && !signatureFromStorage) || !publicKey)
      return;

    const response = await clearBookmarks(
      activeAccount.name,
      signature || signatureFromStorage,
      publicKey,
      proxy === "hivesigner"
    );

    if (!response.ok) {
      return toast("An error occured while clearing bookmarks.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3000
      });
    }

    // await mutate();

    return toast("Folder successfully clearing bookmarks.", {
      type: "success",
      theme: isDarkMode ? "dark" : "light",
      autoClose: 3000
    });
  };

  return (
    <Fragment>
      <div
        ref={reference}
        {...getReferenceProps}
        onClick={() => setOpen(current => !current)}
      >
        {children}
      </div>
      <FloatingPortal>
        {open && (
          <div
            className="hidden pc:block bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl py-1 px-1 text-xs z-[1000] overflow-hidden drop-shadow-md min-w-[150px]"
            ref={floating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            {...getFloatingProps()}
            tabIndex={-1}
          >
            <button
              className="w-full py-2.5 px-5 rounded-lg text-sm font-semibold text-red-500 hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150"
              onClick={() => removeAllBookmarks()}
            >
              Remove all bookmarks
            </button>
          </div>
        )}
      </FloatingPortal>
    </Fragment>
  );
}
