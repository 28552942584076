import { useEffect, useState } from "react";
import { useLocation } from "@remix-run/react";
import { cache } from "~/utils/cache";
import { Spinner } from "./Spinner";
import PollView from "./PollView";
import { PollsPreview } from "./PollsPreview";
import { useAppStore } from "~/store";

interface TickerProps {
  ticker: string;
}

const randomHint = [
  "HBD is a stablecoin that pays 20% APR if you save it in your Hive blockchain wallet",
  "The DHF stands for Decentralized Hive Fund and allows anyone to request funding to build on Hive",
  "Hive processes blocks in just 3 seconds and anyone can publish transactions for free*",
  "Every Thread you post on InLeo is published directly to the Hive blockchain",
  "When you vote on a Poll, you're casting a CUSTOM JSON transaction on a blockchain",
  "Hive uses a consensus mechanism called Delegated Proof of Stake (DPoS)",
  "Powering up the LEO token will give you more influence on inleo.io",
  "The LEO token can be wrapped to different blockchains to earn yield on DeFi platforms"
];

export const tickerNames = {
  HIVE: "hive",
  HBD: "hive_dollar",
  LEO: "wrapped-leo",
  BTC: "bitcoin",
  ETH: "ethereum",
  LINK: "link",
  BNB: "bnb",
  XRP: "ripple",
  DOGE: "dogecoin",
  MATIC: "matic",
  XMR: "monero",
  POLYCUB: "polycub",
  CRV: "curve-dao-token",
  CAKE: "pancakeswap",
  CUB: "cub-finance",
  RUNE: "thorchain",
  SPS: "splintershards",
  BAT: "basic-attention-token",
  LEOPOLL: "leopoll"
};

export default function Ticker({ ticker }: TickerProps) {
  const uppercase = ticker?.toUpperCase();
  const location = useLocation();
  const [activeAccount, prices] = useAppStore(store => [
    store.account.activeAccount,
    store.wallet.tokenPrices
  ]);

  if (uppercase === "LEOPOLL") {
    if (location.pathname === "/publish" || location.pathname === "/publish/") {
      return <PollsPreview />;
    }

    if (location.pathname?.startsWith("/@")) {
      const path = location.pathname.substring(2);
      const [author, permlink] = [path.split("/")[0], path.split("/")[1]];
      const [metadata, setMetadata] = useState<any>(null);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
        void (async function () {
          const content = await cache.getContent({
            permlink,
            author
          });

          if (content) {
            let meta = null;
            try {
              meta = JSON.parse(content.json_metadata);
              setMetadata(meta);
            } catch (err) {
              console.error(err);
            }
          }

          setLoading(false);
        })();
      }, []);

      if (loading) {
        return <Spinner text="" />;
      }

      if (metadata) {
        return (
          <PollView
            author={activeAccount?.name}
            authorPerm={{ author, permlink }}
            pollOptions={metadata.pollOptions}
          />
        );
      }

      return `$${ticker}`;
    }

    if (!location.pathname?.startsWith("/@") && uppercase == "LEOPOLL") {
      // To show the poll, please go to the post page
      return (
        <div className="relative opacity-70       flex flex-1 flex-col max-w-[420px] my-2 mx-auto gap-y-2 p-1.5 rounded-2xl border border-pri dark:border-pri-d">
          <div className="absolute flex w-full h-full items-center justify-center align-center self-center">
            <div className="self-center m-auto font-semibold text-lg text-pretty">
              To view the poll please visit the post.
            </div>
          </div>
          <div className="blur-sm flex flex-1 flex-col">
            <button
              type="button"
              className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
            >
              1
            </button>
            <button
              type="button"
              className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
            >
              2
            </button>
            <button
              type="button"
              className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
            >
              3
            </button>
            <button
              type="button"
              className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
            >
              4
            </button>
            <div className="flex flex-1 justify-between items-center gap-x-4 px-1 text-pri/50 dark:text-pri-d/50">
              <small className="text-xs">0 votes</small>
              <div className="flex items-center gap-x-1.5">
                <button
                  type="button"
                  className="flex text-xs text-pri/50 dark:text-pri-d/50"
                >
                  Edit
                </button>
                <span className="text-xs font-bold">·</span>
                <button
                  type="button"
                  className="flex text-xs text-red-500 font-semibold"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return `$${ticker}`;
  }

  if (!prices)
    return <span className="text-acc dark:text-acc-d">${ticker}</span>;

  //@ts-ignore
  const tickerPrice = prices[tickerNames[ticker]]?.usd || false;
  if (tickerPrice === false) {
    return <span className="text-acc dark:text-acc-d">${uppercase}</span>;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return (
    <span className="bg-acc/[.15] rounded-lg py-1 px-1.5 text-acc dark:text-acc-d">
      ${uppercase}:
      <span className="ml-1 text-acc font-semibold ">
        {formatter.format(Number(tickerPrice))}
      </span>
    </span>
  );
}

