import classNames from "classnames";
import { PollOptionView, PollView } from "./Polls";

export function UnvotedPollView({
  pollOptions,
  pollContent,
  authorPerm,
  author,
  setPollVoteState,
  className
}: PollView) {
  if (
    !author ||
    !authorPerm ||
    !pollOptions ||
    !Object.keys(pollOptions).length
  )
    return null;

  const votes = pollContent?.options
    ? Object.values(pollContent.options).reduce((cur, acc) => cur + acc, 0)
    : 0;

  return (
    <div
      className={classNames(
        "flex flex-1 flex-col max-w-[460px] my-2 mx-auto gap-y-2 p-2 rounded-2xl border border-pri dark:border-pri-d",
        className
      )}
    >
      {Object.keys(pollOptions).map(count => (
        <PollOptionView
          key={+count}
          count={+count}
          setPollVoteState={setPollVoteState}
          author={author}
          authorPerm={authorPerm}
          pollOptions={pollOptions}
        />
      ))}

      <div className="flex flex-1 justify-between items-center gap-x-4 px-1 text-pri/50 dark:text-pri-d/50">
        <small className="text-xs">
          <strong className="font-bold">{votes || 0}</strong> votes
        </small>
      </div>
    </div>
  );
}
