import { FloatingPortal } from "@floating-ui/react";
import React, { useState, useEffect, useRef } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

interface ThreadImagesProps {
  visible: boolean;
  images: string[];
  currentIndex: number;
  onClose: () => void;
}

export default function ImageViewer({
  visible,
  images,
  currentIndex: initialIndex,
  onClose
}: ThreadImagesProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [direction, setDirection] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const viewer = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    ratio: 0
  });
  const [zoomLevel, setZoomLevel] = useState(1);

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = err => cb(err);
    img.src = url;
  };

  useEffect(() => {
    setCurrentIndex(initialIndex);
    setIsInitialRender(true);
  }, [initialIndex]);

  useEffect(() => {
    if (!visible) {
      setIsInitialRender(true);
    }
  }, [visible]);

  useEffect(() => {
    if (!images[currentIndex]) return;

    getMeta(images[currentIndex], (err, img) => {
      if (err) return;

      setDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
        ratio: img.naturalHeight / (img.naturalWidth / 100)
      });
    });
  }, [images, currentIndex]);

  // close on click outside
  // useOnClickOutside(viewer, () => onClose());

  // Add event listeners for zooming
  const handleWheel = e => {
    e.preventDefault();

    // Adjust zoom level based on the scroll direction
    if (e.deltaY > 0) {
      zoomOut();
    } else {
      zoomIn();
    }
  };

  const zoomIn = () => {
    // Increase zoom level, but set a maximum limit
    const newZoomLevel = Math.min(zoomLevel + 0.1, 3.0);
    setZoomLevel(newZoomLevel);
  };

  const zoomOut = () => {
    // Decrease zoom level, but set a minimum limit
    const newZoomLevel = Math.max(zoomLevel - 0.1, 0.5);
    setZoomLevel(newZoomLevel);
  };

  const nextImage = () => {
    setDirection(1);
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    setZoomLevel(1);
    setIsInitialRender(false);
  };

  const prevImage = () => {
    setDirection(-1);
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + images.length) % images.length
    );
    setZoomLevel(1);
    setIsInitialRender(false);
  };

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        onClose();
      } else if (ev.key === "ArrowRight") {
        nextImage();
      } else if (ev.key === "ArrowLeft") {
        prevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  React.useEffect(() => {
    // disable scroll from html not body
    if (visible && images.length) {
      document.documentElement.style.overflow = "hidden";
      // document.documentElement.style.paddingRight = "17px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0";
    }
  }, [visible, images.length]);

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? "200%" : "-200%"
        // opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? "200%" : "-200%"
        // opacity: 0
      };
    }
  };

  return (
    <FloatingPortal>
      <AnimatePresence>
        {visible && images.length ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.15 }}
            className="fixed z-[1000] w-screen h-screen top-0 left-0 overflow-hidden origin-center"
          >
            <div className="relative flex flex-col justify-center items-center w-full h-full z-10">
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
                className="fixed inset-0 w-screen h-screen bg-black bg-opacity-70 z-0"
                onClick={() => onClose()}
              />
              <button
                onClick={() => onClose()}
                className="absolute top-5 right-5 flex items-center justify-center w-10 h-10 rounded-full bg-pri/[.25] dark:bg-pri/[.1] hover:bg-pri/[.35] dark:hover:bg-pri/[.25] transition-colors z-10"
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-pri dark:text-pri-d"
                />
              </button>

              <AnimatePresence initial={false} custom={direction}>
                <motion.div
                  key={currentIndex}
                  custom={direction}
                  variants={isInitialRender ? {} : variants}
                  initial={isInitialRender ? "center" : "enter"}
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 50 },
                    opacity: { duration: 0.3 }
                  }}
                  className="absolute flex justify-center items-center"
                >
                  <div
                    className="relative block overflow-hidden z-10"
                    style={{
                      transform: `scale(${zoomLevel})`,
                      transition: "transform 0.2s"
                    }}
                    onWheel={handleWheel}
                  >
                    <img
                      ref={viewer}
                      src={images[currentIndex]}
                      alt=""
                      className="w-auto h-auto max-h-[calc(100vh-4rem)] max-w-[calc(100vw-4rem)] object-center object-contain"
                      loading="lazy"
                    />
                  </div>
                </motion.div>
              </AnimatePresence>

              {currentIndex !== 0 && images.length > 1 ? (
                <button
                  onClick={prevImage}
                  className="absolute left-5 top-1/2 -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-pri/[.25] dark:bg-pri/[.1] hover:bg-pri/[.35] dark:hover:bg-pri/[.25] transition-colors z-10"
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-pri dark:text-pri-d"
                  />
                </button>
              ) : null}

              {currentIndex !== images.length - 1 && images.length > 1 ? (
                <button
                  onClick={nextImage}
                  className="absolute right-5 top-1/2 -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-pri/[.25] dark:bg-pri/[.1] hover:bg-pri/[.35] dark:hover:bg-pri/[.25] transition-colors z-10"
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-pri dark:text-pri-d"
                  />
                </button>
              ) : null}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </FloatingPortal>
  );
}

