import React from "react";
import { Spinner } from "~/components/format/Spinner";

export default function FileUploadSpinLoader() {
  return (
    <div className="flex justify-center items-start align-middle absolute w-full h-full bg-opacity-5 bg-zinc-500 z-[10000000]  backdrop-blur-[1px]">
      <div className="self-center">
        <Spinner />
      </div>
    </div>
  );
}
