import classNames from "classnames";
import { calculateReputation, type HiveContent } from "~/utils/hive";

interface ReputationProps {
  reputation: HiveContent["author_reputation"];
  className?: string;
}

export default function Reputation({ reputation, className }: ReputationProps) {
  const rep = reputation > 100 ? calculateReputation(reputation) : reputation;

  return (
    <span
      className={classNames(
        "flex shrink-0 w-[26px] h-[26px] items-center justify-center rounded-full border-2 border-bg dark:border-bg-d ml-1 bg-gradient-to-br from-pri-d to-pri-hov-d dark:from-pri dark:to-pri-hov py-1.5 text-xs font-semibold text-pri-d dark:text-pri",
        className
      )}
    >
      {(rep || 25).toFixed(0)}
    </span>
  );
}

