import Popover from "~/components/Popover";
import Logo from "./Logo";

export default function LeoFinanceBadge() {
  return (
    <div className="flex flex-row justify-center content-start text-gray-600 dark:text-gray-400 font-semibold ">
      <span
        id={`leofinance-badge-tooltip`}
        className="relative flex justify-center items-center w-14"
      >
        <Logo />

        <Popover
          anchorId={`leofinance-badge-tooltip`}
          content="This post is published via InLeo."
          place="bottom"
        />
      </span>
    </div>
  );
}

