import { Link } from "@remix-run/react";

interface HashtagProps {
  hashtag: string;
}

export default function Hashtag({ hashtag }: HashtagProps) {
  const lowercase = hashtag?.toLowerCase();
  return (
    <Link
      to={`/threads/tag/${lowercase}`}
      className="text-acc dark:text-acc-d font-semibold"
      onClick={ev => ev.stopPropagation()}
    >
      #{lowercase}
    </Link>
  );
}
