import {
  faArrowLeft,
  faChevronLeft,
  faExternalLink,
  faExternalLinkAlt,
  faHashtag,
  faSearch,
  faSpinner,
  faTimes,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@remix-run/react";
import classNames from "classnames";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from "react";
import { toast } from "react-toastify";
import { GroupedVirtuoso, Virtuoso } from "react-virtuoso";
import {
  useHiveAccountSearch,
  useThreadsAccountSearch,
  useThreadsSearch
} from "~/hooks/contentHooks";
import { useDebounce } from "~/hooks/useDebounce";
import { cache } from "~/utils/cache";
import { useParsedBody } from "~/utils/markdown";
import { SmallAvatar14, SmallAvatar8 } from "./format/SmallAvatar";
import { LoadedPreview } from "./format/Thread";
import { FloatingPortal } from "@floating-ui/react";
import {
  SEARCH_SETTINGS,
  searchTypePlaceHolders
} from "~/config/search.config";
import { LazyMotion, m } from "framer-motion";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import DisplayName from "./format/DisplayName";
import { leocache } from "~/utils/leocache";
import { useAppStore } from "~/store";
const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);

export default function SearchBar() {
  const isDarkMode = useAppStore(store => store.settings.dark);
  const modals = useAppStore(store => store.modals);

  const searchModalRef = useRef<HTMLDivElement>(null);
  const groupRef = useRef<any>(null);

  const [searchStep, setSearchStep] = useState(0);
  const [searchType, setSearchType] = useState<number | undefined>(2);
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  const selectUser = useCallback((userName: string) => {
    setSearchQuery("");
    setSelectedUser(userName);
    setSearchStep(1);
  }, []);

  const debouncedQuery = useDebounce(searchQuery, 350);

  const [fetchState, lookupResults] = useHiveAccountSearch(debouncedQuery);

  const userThreadSearching =
    searchStep === 1 && selectedUser && searchType === 0;
  const threadSearching = searchStep === 0 && searchType === 2;

  const [, accountThreadResults] = useThreadsAccountSearch(
    selectedUser,
    debouncedQuery
  );
  const [, threadResults] = useThreadsSearch(debouncedQuery);

  const [portalPosition, setPortalPosition] = useState({ x: -1, y: -1 });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (modals.search && searchModalRef.current) {
      timeoutId = setTimeout(() => {
        const dimensions = searchModalRef.current.getBoundingClientRect();

        setPortalPosition({
          x: (window.innerWidth - dimensions.width) / 2,
          y: (window.innerHeight * 4) / 100
        });
      }, 150);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [modals.search]);

  const copyToClipboard = useCallback(
    (text: string) => {
      if (!navigator.clipboard) {
        return toast("There is an error while copying text to clipboard.", {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }

      navigator.clipboard.writeText(text);
      toast(`Glossary shortcut copied to clipboard`, {
        type: "success",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    },
    [isDarkMode]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - portalPosition.x,
        y: e.clientY - portalPosition.y
      });
    },
    [portalPosition.x, portalPosition.y]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!searchModalRef.current) return;

      if (isDragging) {
        const MODAL_DIMENSIONS = searchModalRef.current.getBoundingClientRect();

        const fixAntialiasing = (value: number) =>
          Math.ceil(value) % 2 === 0 ? Math.ceil(value) : Math.ceil(value) + 1;

        const newX = fixAntialiasing(e.clientX - dragStart.x);
        const newY = fixAntialiasing(e.clientY - dragStart.y);

        const maxX = fixAntialiasing(
          window.innerWidth - MODAL_DIMENSIONS.width
        );
        const maxY = fixAntialiasing(
          window.innerHeight - MODAL_DIMENSIONS.height
        );

        const updatedX = fixAntialiasing(Math.min(maxX, Math.max(0, newX)));
        const updatedY = fixAntialiasing(Math.min(maxY, Math.max(0, newY)));

        setPortalPosition({ x: updatedX, y: updatedY });
      }
    },
    [isDragging, dragStart.x, dragStart.y]
  );

  const [clickedGlossary, setClickedGlossary] = useState<any>(undefined);
  const [fetchedGlossary, setFetchedGlossary] = useState<any>(undefined);

  useEffect(() => {
    if (!clickedGlossary) return;
    (async function () {
      const content = await cache.getContent(clickedGlossary);
      setFetchedGlossary(content);
    })();
  }, [clickedGlossary]);

  const [leoDocs, setLeoDocs] = useState<any[]>([]);
  const [leoGlossary, setLeoGlossary] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    (async function () {
      setTags(await leocache.trendingTags);
      setLeoDocs(await (await fetch("/leodocs.json")).json());
      setLeoGlossary(await (await fetch("/leoglossary.json")).json());
    })();
  }, []);

  const searchResults = useMemo(() => {
    return leoDocs.filter(item =>
      item?.text?.toLowerCase()?.includes(debouncedQuery?.toLowerCase())
    );
  }, [leoDocs, debouncedQuery]);

  const parsedBody = useParsedBody(
    fetchedGlossary?.body?.replaceAll(
      "[How to get a Hive Account](https://leofinance.io/@leoglossary/leoglossary-how-to-create-a-hive-account)",
      ""
    )
  );

  const groupGlossery = useCallback((arr: any[]) => {
    if (!arr) return [];

    const grouped: any = {};

    arr.forEach(obj => {
      const firstChar = obj.text.charAt(0).toUpperCase();

      if (!grouped[firstChar]) {
        grouped[firstChar] = [];
      }

      grouped[firstChar].push(obj);
    });

    return grouped;
  }, []);

  const ungroupGlossery = useCallback((arr: any[]) => {
    if (!arr) return [];

    return arr;
  }, []);

  const groupedGlossery = useMemo(() => {
    return groupGlossery(leoGlossary);
  }, [leoGlossary, groupGlossery]);

  const searchedGroupedGlossery = useMemo(() => {
    if (!searchQuery) return groupedGlossery;

    const unwrap = ungroupGlossery(leoGlossary);

    const wrapped = groupGlossery(
      unwrap.filter(
        term =>
          (term.text as string)
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase()) || false
      )
    );

    return wrapped;
  }, [
    groupedGlossery,
    searchQuery,
    ungroupGlossery,
    groupGlossery,
    leoGlossary
  ]);

  useEffect(() => {
    const keyDownEvent = (ev: KeyboardEvent) => {
      if (ev.key === "k" && (ev.ctrlKey === true || ev.metaKey === true)) {
        modals.setModal("search", true);
        ev.preventDefault();
      }
      if (ev.key === "Escape") {
        modals.setModal("search", false);
      }
    };

    window.addEventListener("keydown", keyDownEvent);
    return () => window.removeEventListener("keydown", keyDownEvent);
  }, [modals]);

  const transition = {
    type: "tween",
    ease: "linear",
    duration: 0.1
  };

  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {modals.search ? (
          <m.div
            key="search-modal"
            initial={{ opacity: 0.5, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0.5, scale: 0.98 }}
            transition={transition}
            className="fixed top-0 left-0 origin-center z-[10000]"
          >
            <div
              ref={searchModalRef}
              className="relative z-[10000] select-none p-3 sm:p-12 -ml-1.5 sm:ml-0 transition-opacity duration-150"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              style={{
                maxHeight: "75vh",
                minHeight: "40vh",
                transform: `translate(${portalPosition.x}px, ${portalPosition.y}px)`,
                opacity: Object.values(portalPosition)?.some(v => v === -1)
                  ? 0
                  : 1
              }}
            >
              <div
                style={{
                  maxHeight: "75vh",
                  minHeight: "40vh"
                }}
                className={classNames(
                  "flex flex-col select-none bg-pri dark:bg-pri-d rounded-xl min-w-[300px] pc:min-w-[540px] max-w-[calc(100vw-12px)] sm:max-w-[613px] p-6 radius-lg border dark:border-pri-d/[.5] drop-shadow-lg shadow-[0_0_12px_3px_rgb(255_255_255_/_20%)] cursor-grab",
                  {
                    "cursor-grabbing": isDragging
                  }
                )}
              >
                <div className="relative flex items-center text-2xl justify-between font-bold w-full flex-row pb-3">
                  {selectedUser && searchType === 0 && (
                    <button
                      className="flex justify-center items-center w-10 h-10 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] hover:text-pri dark:hover:text-pri-d dark:hover:bg-pri/[.075] text-pri/60 dark:text-pri-d/60 transition-colors cursor-pointer"
                      onClick={() => {
                        setSelectedUser(undefined);
                        setSearchType(0);
                        setSearchStep(0);
                        setSearchQuery(undefined);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        size="xs"
                        fixedWidth
                      />
                    </button>
                  )}
                  <div>
                    Search{" "}
                    {SEARCH_SETTINGS[searchType!] === "INLEO"
                      ? "InLeo"
                      : SEARCH_SETTINGS[searchType!].toLocaleLowerCase("en-US")}
                    {selectedUser && <strong> {selectedUser}</strong>}
                  </div>
                  <div
                    className="-mt-0.5 p-4 h-10 w-10 flex items-center justify-center rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] hover:text-pri dark:hover:text-pri-d dark:hover:bg-pri/[.075] text-pri/60 dark:text-pri-d/60 transition-colors cursor-pointer"
                    onClick={() => modals.setModal("search", false)}
                  >
                    <FontAwesomeIcon icon={faXmark} size="xs" fixedWidth />
                  </div>
                </div>
                {searchType !== undefined && (
                  <div className="py-4">
                    <section className="relative flex flex-1 items-center">
                      <span className="absolute left-4 text-gray-500 dark:text-zinc-500">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>

                      <input
                        autoFocus
                        className="flex flex-1 py-2.5 px-5 pl-11 rounded-full bg-transparent outline-none border border-pri dark:border-pri-d placeholder:text-gray-500 dark:placeholder:text-zinc-500 text-sm focus:bg-pri-d/[0.075] dark:focus:bg-pri/[0.075] transition-all duration-150"
                        placeholder={searchTypePlaceHolders
                          ?.at(searchType)
                          ?.at(searchStep)}
                        value={searchQuery}
                        onChange={event => setSearchQuery(event?.target?.value)}
                      />
                      {searchQuery && (
                        <span
                          className="absolute right-3 w-6 h-6 flex items-center justify-center rounded-full text-gray-500 dark:text-zinc-500 hover:bg-gray-100 dark:hover:bg-zinc-800 transition-colors cursor-pointer z-10"
                          onClick={() => setSearchQuery("")}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      )}
                    </section>
                  </div>
                )}
                <div
                  className="relative flex shrink-0 flex-row flex-nowrap whitespace-nowrap pb-3 gap-1.5 w-[calc(100%+20px)] -left-5 px-5 cursor-auto scrollbar-none overflow-x-auto"
                  style={{
                    maskImage:
                      "linear-gradient(to left, transparent, black 20px, black calc(100% - 20px), transparent)"
                  }}
                >
                  {SEARCH_SETTINGS.map(
                    (type, i) =>
                      i !== 1 && (
                        <button
                          key={i}
                          onClick={() => setSearchType(i)}
                          type="button"
                          title={type}
                          aria-label={type}
                          className={classNames(
                            "flex items-center gap-x-2 border w-fit cursor-pointer px-4 py-2 rounded-full text-pri dark:text-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150",
                            {
                              "border-pri dark:border-pri-d": searchType !== i,
                              "bg-pri-d/[0.075] dark:bg-pri/[0.075] border-bg-d dark:border-bg":
                                searchType === i
                            }
                          )}
                        >
                          {type}
                        </button>
                      )
                  )}
                </div>

                <div className="relative flex flex-1 flex-col h-full gap-y-2 overflow-y-scroll cursor-auto scrollbar-none">
                  {!searchQuery &&
                    searchType !== 4 &&
                    searchType !== 3 &&
                    searchType !== 6 && (
                      <div className="flex flex-col items-center my-auto text-center py-12">
                        <span className="text-5xl min-h-[48px]">🤔</span>
                        <strong className="text-2xl font-bold">
                          Let's type something to search
                        </strong>
                      </div>
                    )}

                  {(searchType === 0 &&
                    searchStep === 1 &&
                    searchQuery &&
                    !accountThreadResults.length) ||
                    (searchType === 2 &&
                      searchQuery &&
                      threadSearching &&
                      !threadResults.length && (
                        <div className="flex flex-col flex-1 items-center justify-center">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            size="sm"
                            fixedWidth
                            className="animate-spin"
                          />
                        </div>
                      ))}

                  {searchStep === 0 &&
                    (searchType === 0 || searchType === 1) &&
                    searchQuery &&
                    lookupResults && (
                      <div className="flex flex-1 flex-col gap-y-5">
                        <div className="flex flex-col scrollbar-none">
                          {lookupResults.map((account, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => selectUser(account)}
                                className="cursor-pointer select-none flex items-center py-3 gap-x-3 border-b border-pri/50 dark:border-pri-d/50 last:border-b-0 hover:opacity-75 transition-opacity duration-150"
                              >
                                <SmallAvatar14 author={account} />
                                <DisplayName
                                  authorName={account}
                                  name={account}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  {searchType === 5 && searchQuery && lookupResults && (
                    <div className="flex flex-1 flex-col gap-y-5">
                      <div className="flex flex-col scrollbar-none">
                        {lookupResults.map((account, index: number) => (
                          <Link
                            key={index}
                            to={`/@${account}`}
                            className="cursor-pointer select-none flex items-center py-3 gap-x-3 border-b border-pri/50 dark:border-pri-d/50 last:border-b-0 hover:opacity-75 transition-opacity duration-150"
                          >
                            <SmallAvatar14 author={account} />
                            <DisplayName name={account} authorName={account} />
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  {searchType === 4 && (
                    <div className="relative flex flex-col overflow-y-scroll scrollbar-none">
                      {searchResults.map(({ text, url }) => (
                        <Link
                          key={text}
                          to={url}
                          className="flex items-center py-1 px-2.5 border-b border-pri/50 dark:border-pri-d/50 last:border-b-none hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors duration-150"
                        >
                          <div className="cursor-pointer select-none flex items-center py-3 gap-x-3">
                            <div className="w-6 h-6 overflow-hidden rounded-full">
                              <img src="https://inleo.io/logo.svg" />
                            </div>
                            <span className="text-sm font-medium">{text}</span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}

                  {/* glossary */}
                  {searchType === 3 && (
                    <React.Fragment>
                      <div className="hidden sm:flex items-center w-full whitespace-nowrap overflow-auto scrollbar-none">
                        {Object.keys(searchedGroupedGlossery).map((w, i) => (
                          <button
                            key={i}
                            className="flex shrink-0 py-2 px-2.5 font-bold text-xl uppercase text-pri dark:text-pri-d text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d transition-colors duration-150"
                            onClick={() => {
                              if (!groupRef.current) return;

                              const index = Object.values(
                                searchedGroupedGlossery
                              )
                                .flat()
                                .findIndex(x => x.text?.startsWith(w));

                              if (index === -1) return;

                              groupRef.current.scrollToIndex(index);
                            }}
                          >
                            {w}
                          </button>
                        ))}
                      </div>
                      <GroupedVirtuoso
                        ref={groupRef}
                        groupCounts={
                          Object.values(searchedGroupedGlossery).map(
                            v => v.length
                          ) as unknown as number[]
                        }
                        className="flex flex-1 flex-col gap-x-5 h-full min-h-[300px] scrollbar-none"
                        data={Object.values(searchedGroupedGlossery).flat()}
                        increaseViewportBy={{ top: 2500, bottom: 2500 }}
                        groupContent={index => (
                          <div className="bg-pri dark:bg-pri-d border-b border-pri dark:border-pri-d py-0.5 font-bold text-sm">
                            {Object.keys(searchedGroupedGlossery)[index]}
                          </div>
                        )}
                        itemContent={i => {
                          return (
                            <div className="flex justify-between items-center py-1.5 px-1 gap-x-3 border-b border-pri/50 dark:border-pri-d/50">
                              <div className="flex items-center flex-row gap-x-3">
                                <span className="flex shrink-0">
                                  <SmallAvatar8 author="leoglossary" />
                                </span>
                                <span className="font-medium text-sm">
                                  {
                                    Object.values(
                                      searchedGroupedGlossery
                                    ).flat()[i]?.text
                                  }
                                </span>
                              </div>
                              <div className="flex flex-row gap-x-1 p-1 text-sm">
                                <div
                                  onClick={() =>
                                    copyToClipboard(
                                      `<a href='${
                                        Object.values(
                                          searchedGroupedGlossery
                                        ).flat()[i]?.url
                                      }'>${Object.values(
                                        searchedGroupedGlossery
                                      )
                                        .flat()
                                        [i]?.text?.toLowerCase()}</a>`
                                    )
                                  }
                                  className="py-1 px-2.5 border-pri dark:border-pri-d border rounded-xl cursor-pointer select-none hover:opacity-75 transition-opacity duration-150"
                                >
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    size="sm"
                                    fixedWidth
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    const permlink = Object.values(
                                      searchedGroupedGlossery
                                    )
                                      .flat()
                                      [i]?.url?.replaceAll(
                                        "https://inleo.io/@leoglossary/",
                                        ""
                                      );
                                    setClickedGlossary({
                                      author: "leoglossary",
                                      permlink,
                                      title: Object.values(
                                        searchedGroupedGlossery
                                      ).flat()[i]?.text
                                    });
                                  }}
                                  className="text-xs font-medium py-1 px-2.5 border-pri dark:border-pri-d border rounded-xl cursor-pointer select-none hover:opacity-75 transition-opacity duration-150"
                                >
                                  Explanation
                                </div>

                                <Link
                                  className="text-xs font-medium flex items-center gap-x-1.5 py-1 px-2.5 border-pri dark:border-pri-d border rounded-xl cursor-pointer select-none hover:opacity-75 transition-opacity duration-150 "
                                  to={
                                    Object.values(
                                      searchedGroupedGlossery
                                    ).flat()[i]?.url
                                  }
                                >
                                  Visit
                                  <FontAwesomeIcon
                                    icon={faExternalLink}
                                    size="xs"
                                    fixedWidth
                                  />
                                </Link>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </React.Fragment>
                  )}

                  {/* gloassary explanation */}
                  <LazyMotion features={loadFeatures}>
                    {fetchedGlossary && clickedGlossary && (
                      <m.div
                        key="gloassary-explanation"
                        initial={{
                          opacity: 0,
                          scale: 0.97,
                          x: "-5%"
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          x: "0"
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0.97,
                          x: "-5%"
                        }}
                        transition={transition}
                        className="absolute inset-0 w-full h-full py-4 px-2 bg-pri dark:bg-pri-d select-none z-[10001]"
                      >
                        <div className="flex items-center gap-x-3 pb-4">
                          <button
                            type="button"
                            className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/50 dark:text-pri-d/50 hover:border-pri dark:hover:border-pri-d hover:text-pri dark:hover:text-pri-d transition-colors duration-150"
                            onClick={() => {
                              setClickedGlossary(undefined);
                              setFetchedGlossary(undefined);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              size="sm"
                              fixedWidth
                            />
                          </button>

                          <strong className="text-lg font-bold">
                            {clickedGlossary?.title}
                          </strong>
                        </div>
                        <div className="prose dark:prose-invert text-[#232323] dark:text-[#e5e5e5] mt-2 pr-4">
                          {parsedBody}
                        </div>
                      </m.div>
                    )}
                  </LazyMotion>

                  {searchQuery &&
                    userThreadSearching &&
                    accountThreadResults.length > 0 && (
                      <div className="relative flex flex-1 flex-col h-full w-full">
                        <Virtuoso
                          height="100%"
                          className="flex flex-1 flex-col gap-4 w-full h-full min-h-[360px] scrollbar-none"
                          data={accountThreadResults}
                          computeItemKey={(i, item) =>
                            item?.content?.permlink || i
                          }
                          increaseViewportBy={{ top: 1500, bottom: 1500 }}
                          itemContent={(i, threadContent) => (
                            <div className="mb-2">
                              <LoadedPreview data={threadContent} />
                            </div>
                          )}
                        />
                      </div>
                    )}
                  {searchQuery &&
                    threadSearching &&
                    threadResults.length > 0 && (
                      <div className="relative flex flex-1 flex-col h-full w-full overflow-auto">
                        <Virtuoso
                          className="flex flex-col flex-1 gap-y-4 w-full h-full min-h-[360px] scrollbar-none"
                          data={threadResults}
                          computeItemKey={(i, item) =>
                            item?.content?.permlink || i
                          }
                          increaseViewportBy={{ top: 1500, bottom: 1500 }}
                          itemContent={(i, threadContent) => (
                            <div className="mb-2">
                              <LoadedPreview data={threadContent} />
                            </div>
                          )}
                        />
                      </div>
                    )}
                  {searchType === 6 && (
                    <div className="relative flex flex-1 flex-col h-full w-full">
                      <Virtuoso
                        height="100%"
                        className="flex flex-1 flex-col gap-4 w-full h-full min-h-[360px] scrollbar-none"
                        data={(tags || []).filter(t =>
                          t.tag?.includes(searchQuery)
                        )}
                        computeItemKey={(i, item) => item?.tag || i}
                        increaseViewportBy={{ top: 1500, bottom: 1500 }}
                        itemContent={(i, item) => <Tag tag={item} />}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </m.div>
        ) : null}
      </LazyMotion>
    </FloatingPortal>
  );
}

function Tag({ tag }: { tag: { tag: string; count: number } }) {
  return (
    <Link
      to={`/threads/tag/${tag.tag}`}
      aria-label={tag.tag}
      className="group flex items-center w-full gap-x-3 py-2.5 px-1 border-b border-pri/50 dark:border-pri-d/50 hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors duration-150"
    >
      <FontAwesomeIcon icon={faHashtag} size="lg" fixedWidth />

      <div className="flex flex-1 flex-col">
        <span className="font-medium">{tag.tag}</span>
        <span className="text-xs text-pri/60 dark:text-pri-d/60 leading-snug">
          {tag.count} threads
        </span>
      </div>

      <span className="flex opacity-0 transition-opacity duration-150 group-hover:opacity-100">
        <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" fixedWidth />
      </span>
    </Link>
  );
}

