import { useState, useEffect } from "react";
import {
  updateVideoPost,
  getUserVideos,
  uploadFile,
  updateVideoDetails
} from "~/utils/single/3speak";
import { getAccessToken } from "~/utils/threespeak";

export const useUpdateVideoPost = () => {
  const [response, setResponse] = useState<VideoStatusResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updatePost = async (
    videoId: string,
    title: string,
    description: string,
    tags: string,
    thumbnail: string,
    isNsfwContent: boolean,
    cookie: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const result = await updateVideoPost(
        videoId,
        title,
        description,
        tags,
        thumbnail,
        isNsfwContent,
        cookie
      );
      setResponse(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { response, updatePost, error, loading };
};

export const useUserVideos = (cookie: string) => {
  const [videos, setVideos] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getUserVideos(cookie);
        setVideos(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (cookie) {
      fetchVideos();
    }
  }, [cookie]);

  return { videos, error, loading };
};

export const useFileUpload = () => {
  const [uploadResponse, setUploadResponse] = useState<UploadResponse | null>(
    null
  );
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const upload = async (file: File) => {
    setLoading(true);
    setError(null);
    try {
      const response = await uploadFile(file, percentage =>
        setProgress(percentage)
      );
      setUploadResponse(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { uploadResponse, upload, progress, error, loading };
};

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAccessToken = async (username: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getAccessToken(username);
      setAccessToken(response.memo);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { accessToken, fetchAccessToken, error, loading };
};

export const useUpdateVideoDetails = () => {
  const [response, setResponse] = useState<VideoStatusResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateDetails = async (details: VideoDetails, cookie: string) => {
    setLoading(true);
    setError(null);
    try {
      const result = await updateVideoDetails(details, cookie);
      setResponse(result);
      return result;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { response, updateDetails, error, loading };
};
