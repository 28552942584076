import { AuthorPerm } from "./hive";
import { addBookmark, removeBookmark, Bookmark } from "./leocache";

/**
 * shared bookmark utils
 */
export const writeLocalStorageBookmarks = (bookmark: Object) => {
  let currentStorage;
  let _localStorage = localStorage.getItem("bookmarks") || "[]";
  currentStorage = JSON.parse(_localStorage);

  if (typeof currentStorage !== "object") {
    localStorage.setItem("bookmarks", JSON.stringify([bookmark]));
  } else {
    localStorage.setItem(
      "bookmarks",
      JSON.stringify([...new Set([bookmark, ...currentStorage])])
    );
  }
};

export const readLocalStorageBookmarks = () => {
  let _localStorage = localStorage.getItem("bookmarks") || "[]";
  return JSON.parse(_localStorage);
};

export const addToBookmarks = async ({
  author,
  permlink,
  folder,
  account,
  signature,
  publicKey,
  proxy
}: AuthorPerm & { folder: string }) => {
  const response = await addBookmark(
    author,
    permlink,
    folder,
    account,
    signature,
    publicKey,
    proxy === "hivesigner"
  );
};

export const removeFromBookmarks = async ({
  author,
  permlink,
  folder,
  account,
  signature,
  publicKey,
  proxy
}: AuthorPerm & { folder: string }) => {
  const response = await removeBookmark(
    author,
    permlink,
    folder,
    account,
    signature,
    publicKey,
    proxy === "hivesigner"
  );
};

export const findFromBookmarks = ({
  bookmarks,
  author,
  permlink
}: AuthorPerm & { bookmarks: Bookmark[] }) => {
  if (!bookmarks) return false;

  return bookmarks.find(bookmark =>
    bookmark.permlinks?.includes(`${author}/${permlink}`)
  );
};
