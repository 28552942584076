interface SpotifyEmbed {
  track: string;
}

export const SpotifyEmbed = ({ track }: SpotifyEmbed) => {
  return (
    <div className="w-full rounded-lg overflow-hidden flex flex-row justify-center">
      <div className="w-[98%] rounded-xl overflow-hidden">
        <iframe
          loading="lazy"
          src={`https://open.spotify.com/embed/track/${track}?utm_source=generator`}
          width="100%"
          height="152"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          title="Embedded Spotify"
          loading="lazy"
        />
      </div>
    </div>
  );
};
