import { useCallback } from "react";
import { PollView, PollVotedOptionView } from "./Polls";

export function VotedPollView({ pollOptions, pollContent }: PollView) {
  const { account, options, voterdata } = pollContent;

  const sum = options[1] + options[2] + options[3] + options[4];

  const results = [1, 2, 3, 4];

  const calculatePercent = useCallback((d: number): number => {
    return options[d] && sum ? (options[d] / sum) * 100 : 0;
  }, []);

  return (
    <div className="flex flex-1 flex-col max-w-[420px] my-2 mx-auto gap-y-2 p-1.5 rounded-2xl border border-pri dark:border-pri-d">
      {results.map((d: number) => {
        return (
          <PollVotedOptionView
            key={d}
            optionName={pollOptions[d]}
            isVoted={account.option === d}
            percent={calculatePercent(d)}
            voters={voterdata[d]}
          />
        );
      })}
      <span className="p-1 text-sm select-none cursor-default">
        Total <strong className="font-bold">{sum}</strong> votes
      </span>
    </div>
  );
}
