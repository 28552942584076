import type { ThreadIndexed } from "~/utils/thread";
import AccountName from "../format/AccountName";
import Reputation from "../format/Reputation";
import { SmallAvatar14 } from "../format/SmallAvatar";
import { ReplyCount } from "../posts/Comments";
import { LoadingIndicator } from "./LoadingIndicator";
import {
  ThreadContainer,
  ThreadBody,
  ThreadHeader,
  ThreadFooter,
  ThreadText
} from "./ThreadViewHelpers";
import DisplayName from "../format/DisplayName";
import { PostInteractions } from "../posts/PostInteractions";
import { ThreadActions } from "./ThreadActions";
import { TimeSincePublish } from "../TimeSincePublish";

interface LoadingThreadProps {
  threadIndexed: ThreadIndexed;
  className?: string;
}

export function LoadingThread({
  threadIndexed,
  className
}: LoadingThreadProps) {
  const [author, children] = [threadIndexed?.author, threadIndexed?.children];

  return (
    <ThreadContainer className={className}>
      <div className="flex flex-col !justify-between pr-4">
        <div className="relative">
          <span className="flex shrink-0 size-14 rounded-full bg-gray-200 dark:bg-zinc-800">
            <SmallAvatar14 author={author} />
          </span>
          <div className="absolute -bottom-0.5 -right-0.5">
            <Reputation reputation={0} />
          </div>
        </div>
      </div>
      <ThreadBody>
        <ThreadHeader>
          <div className="flex flex-row mt-1">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <DisplayName
                  authorName={author}
                  name={author}
                  premium={false}
                  subscriptions={[]}
                />
              </div>

              <div className="flex flex-row items-center gap-1">
                <AccountName author={author} />
                <span className="flex text-gray-500"> · </span>
                <TimeSincePublish publishTime={""} utc={false} />
              </div>
            </div>
          </div>
        </ThreadHeader>
        <ThreadText>
          <LoadingIndicator />
        </ThreadText>
        <ThreadFooter>
          <PostInteractions className="relative flex gap-x-4 !p-none -ml-1 pointer-events-none">
            <ReplyCount replyCount={children ?? 0} threadContent={undefined} />

            <ThreadActions
              content={null}
              fake={false}
              downvote={false}
              rethread={false}
              editThread={false}
              setDownvote={() => {}}
              setRethread={() => {}}
              setEditThread={() => {}}
            />
          </PostInteractions>
        </ThreadFooter>
      </ThreadBody>
    </ThreadContainer>
  );
}

