import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarth } from "@fortawesome/free-solid-svg-icons";

interface SelectCommunityButtonProps {
  selectedCommunity: any;
  setPublishChooseCommunities: any;
}

const SelectCommunityButton = ({
  selectedCommunity,
  setPublishChooseCommunities
}: SelectCommunityButtonProps) => {
  return (
    <button
      type="button"
      title="Select Community"
      aria-label="Select Community"
      className="w-fit flex items-center gap-1 p-1.5 rounded-3xl bg-gray-200 dark:bg-zinc-700 text-pri dark:text-pri-d text-sm font-semibold hover:opacity-75 transition-opacity duration-150"
      onClick={() => setPublishChooseCommunities(true)}
    >
      <FontAwesomeIcon icon={faEarth} size="lg" />
      {selectedCommunity.at(0)?.label ? (
        <span className="hidden tbl:flex text-xs pl-1 pr-1">
          {selectedCommunity.at(0)?.label}
        </span>
      ) : null}
    </button>
  );
};

export default SelectCommunityButton;
