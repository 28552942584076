import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

interface WarningMessageProps {
  message: string;
}

const WarningMessage = ({ message }: WarningMessageProps) => {
  return (
    <div className="flex w-full p-2">
      <div className="flex items-center gap-x-4 py-2 px-4 rounded-lg bg-yellow-500/10 text-yellow-500 leading-5 text-sm font-medium">
        <FontAwesomeIcon icon={faWarning} size="sm" fixedWidth />
        <span>{message}</span>
      </div>
    </div>
  );
};

export default WarningMessage;
