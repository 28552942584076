import React from "react";
import { leocache } from "~/utils/leocache";

export function useCreatorSubscriptions(account?: string, disabled?: boolean) {
  const [subscriptions, setSubscriptions] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!account || disabled) return;

    void (async function () {
      const subs = await leocache.getCreatorSubscriptions(account, true);
      setSubscriptions(subs);
    })();
  }, [account, disabled]);

  return subscriptions;
}

export function useCreatorSubscribers(account?: string) {
  const [subscribers, setSubscribers] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!account) return;

    void (async function () {
      const subs = await leocache.getCreatorSubscribers(account, true);
      setSubscribers(subs);
    })();
  }, [account]);

  return subscribers;
}
