import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import type { AuthorPerm } from "~/utils/hive";
import type { Thread } from "~/utils/leocache";
import { leocache } from "~/utils/leocache";
import {
  isImageLink,
  useParsedBody,
  useStrippedSlicedBody
} from "~/utils/markdown";
import { TimeSincePublish } from "../TimeSincePublish";
import { SmallAvatar10 } from "./SmallAvatar";
import DisplayName from "./DisplayName";
import Reputation from "./Reputation";
import { useNavigate } from "@remix-run/react";
import ProfileLink from "~/components/ProfileLink";
import {
  ThreadBody,
  ThreadContainer,
  ThreadHeader
} from "../threads/ThreadViewHelpers";
import { LoadingIndicator } from "../threads/LoadingIndicator";
import { useDecryption } from "~/hooks/useDecryption";
import { DecryptLoader } from "../general/DecryptLoader";
import { SubscribeToCreatorThread } from "../general/SubscribeToCreator";
import ImagesGrid from "./ImagesGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";

export default function ReThread({ author, permlink }: AuthorPerm) {
  const navigate = useNavigate();
  const [threadData, setThreadData] = useState<Thread | null>(null);

  const metadata = useMemo(() => {
    if (!threadData) return;
    if (typeof threadData.json_metadata !== "string")
      return threadData.json_metadata;

    let _;
    try {
      _ = JSON.parse(threadData.json_metadata);
    } catch {
      return;
    }

    return _;
  }, [threadData]);

  const encrypted = useMemo(() => metadata?.encrypted ?? false, [metadata]);

  const { decryptedBody, decryptionFailed, decrypting } = useDecryption(
    threadData,
    encrypted ?? false,
    true
  );

  const recursiveRethread = useMemo(() => {
    if (!decryptedBody) return;

    const words = decryptedBody.split(" ");

    if (words.length === 0) return;

    const rethread =
      words.find(
        w => w.startsWith("https://") && w.includes("/threads/view")
      ) || undefined;
    return rethread;
  }, [decryptedBody]);

  const slicedBody = useStrippedSlicedBody(decryptedBody ?? "", 170);

  const images = useMemo(() => {
    if (!threadData) return [];

    const links = threadData.links?.filter(isImageLink) || [];

    const imagesFromMetadata = threadData.images || [];

    const modifyImageUrls = (url: string) => {
      return url.replaceAll(")", "").replaceAll("(", "");
    };

    const merged = links.concat(imagesFromMetadata).map(modifyImageUrls);

    return merged;
  }, [threadData]);

  useEffect(() => {
    if (!author || !permlink) return;

    void (async function () {
      const fetchThread = await leocache.singleThread({
        author,
        permlink
      } as AuthorPerm);
      setThreadData(fetchThread[0] || null);
    })();
  }, [author, permlink]);

  const onPreviewClick = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    navigate(`/threads/view/${author}/${permlink}`);
  };

  const parsedBody = useMemo((): React.ReactNode => {
    if (!recursiveRethread) return slicedBody;

    const isLengthExceed =
      (slicedBody ?? "").length + recursiveRethread.length > 170;
    const remainingCharLimit = isLengthExceed
      ? 170 - (slicedBody ?? "").length
      : undefined;

    return (
      <Fragment>
        {slicedBody}{" "}
        {slicedBody.length === 0 ? (
          <span className="flex items-center gap-x-2 pb-1 text-pri/60 dark:text-pri-d/60 text-sm">
            <FontAwesomeIcon icon={faRepeat} size="sm" fixedWidth />
            <span>Rethreaded</span>
          </span>
        ) : null}
        <i>
          {remainingCharLimit
            ? `${recursiveRethread.slice(0, remainingCharLimit)}...}`
            : recursiveRethread}
        </i>
      </Fragment>
    );
  }, [slicedBody, recursiveRethread]);

  if (!threadData) return <LoadingReThread author={author} />;
  return (
    <span
      onClick={onPreviewClick}
      className="rethread relative flex flex-1 flex-col w-full border my-2 border-pri rounded-xl dark:border-pri-d px-3.5 pt-3.5 pb-3 z-[99] overflow-hidden text-sm font-sans text-pri dark:text-pri-d hover:bg-pri-d/5 dark:hover:bg-pri/5 transition-colors duration-150 cursor-pointer"
      data-prevent-routing
    >
      <span className="flex flex-row pb-4">
        <span className="flex flex-col items-start">
          <span className="flex flex-row items-center gap-2">
            <span className="relative flex shrink-0">
              <SmallAvatar10
                author={threadData?.author || author}
                disableThreadcast
              />
            </span>
            <span className="flex flex-col items-start">
              <ProfileLink
                children={
                  <DisplayName
                    className="text-[15px] no-underline"
                    name={threadData?.author}
                  />
                }
                accountName={threadData?.author}
              />
              <TimeSincePublish
                publishTime={threadData?.created}
                utc={false}
                className="text-xxs tracking-normal mt-px"
              />
            </span>
          </span>
        </span>
      </span>

      <span className="flex flex-row items-start gap-x-3 h-[72px] overflow-hidden">
        {images.length > 0 && slicedBody?.length > 0 ? (
          <span className="flex shrink-0 size-[72px] bg-gray-200 dark:bg-zinc-800 rounded-xl overflow-hidden">
            <img
              src={images[0]}
              alt="Images from thread"
              width={72}
              height={72}
              className="size-[72px] object-cover"
              style={{ margin: 0, padding: 0 }}
            />
          </span>
        ) : null}

        <span className="flex flex-col w-full mb-0 leading-snug prose prose-base dark:prose-invert font-normal px-0.5 overflow-hidden">
          {encrypted ? (
            decrypting ? (
              <DecryptLoader />
            ) : decryptionFailed ? (
              <SubscribeToCreatorThread
                creator={threadData?.author}
                className="py-0 px-0"
              />
            ) : (
              parsedBody
            )
          ) : (
            parsedBody
          )}

          {slicedBody?.length === 0 ? <ImagesGrid images={images} /> : null}
        </span>
      </span>
    </span>
  );
}

function LoadingReThread({ author }: { author: string }) {
  return (
    <span
      className="rethread relative flex flex-1 flex-col w-full border my-2 border-pri rounded-xl dark:border-pri-d px-3.5 pt-3.5 pb-3 min-h-[145px] z-[99] overflow-hidden text-sm font-sans text-pri dark:text-pri-d hover:bg-pri-hov dark:hover:bg-pri-hov-d transition-colors duration-150"
      suppressHydrationWarning
    >
      <ThreadContainer className="p-0">
        <span className="flex flex-col !justify-between pr-3">
          <span className="relative">
            <SmallAvatar10 author={author} />
            {/* <span className="absolute -bottom-1 -right-1">
              <Reputation
                reputation={0}
                className="text-xs max-w-[24px] max-h-[24px]"
              />
            </span> */}
          </span>
        </span>
        <ThreadBody className="gap-y-3">
          <ThreadHeader>
            <span className="flex flex-row mt-1">
              <span className="flex flex-col">
                <span className="flex flex-col items-start">
                  <DisplayName
                    authorName={author}
                    name={author}
                    premium={false}
                    subscriptions={[]}
                    className="text-[15px]"
                  />
                  <TimeSincePublish
                    publishTime={new Date().toISOString()}
                    utc={false}
                    className="text-xxs tracking-normal mt-px"
                  />
                </span>
              </span>
            </span>
          </ThreadHeader>
          <span className="flex flex-col w-full h-20 overflow-hidden">
            <LoadingIndicator />
          </span>
        </ThreadBody>
      </ThreadContainer>
    </span>
  );
}

export function LoadedPreview({ data }) {
  const navigate = useNavigate();
  const onPreviewClick = (ev: MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    ev.preventDefault();
    return navigate(`/threads/view/${data.author}/${data.permlink}`);
  };

  const { body, author, author_reputation, created, permlink } = data;

  const parsedBody = useParsedBody(body || "") || "";

  return (
    <div
      onClick={onPreviewClick}
      className="flex flex-col cursor-pointer border border-pri rounded-xl dark:border-pri-d px-4 py-4 gap-3 overflow-hidden text-sm text-pri dark:text-pri-d hover:bg-pri-hov dark:hover:bg-pri-hov-d transition-colors duration-150 *:no-underline"
    >
      <span className="flex flex-row">
        <span className="flex flex-col pb-5">
          <span className="flex flex-row items-center gap-3">
            <span className="relative flex shrink-0">
              <SmallAvatar10 author={author || author} />
              <span className="absolute -right-1 -bottom-1">
                <Reputation
                  reputation={author_reputation}
                  className="text-xxs max-w-[24px] max-h-[24px]"
                />
              </span>
            </span>
            <span className="flex flex-col">
              <ProfileLink
                children={
                  <DisplayName
                    name={author}
                    className="font-bold text-md dark:text-white sans-serif justify-center"
                  />
                }
                accountName={author}
                referrer={author}
              />
              <TimeSincePublish
                publishTime={created}
                utc={false}
                className="text-xxs pt-0.5 tracking-normal leading-none"
              />
            </span>
          </span>
        </span>
      </span>

      <span className="-mt-4 mb-0 overflow-hidden">{parsedBody}</span>
    </div>
  );
}
