import { useState } from "react";

interface ImageProps {
  src: string;
  alt?: string;
}

export default function Images({ src, alt }: ImageProps) {
  const isGif = src?.endsWith?.(".gif") || false;
  const isImgur = src?.startsWith("https://i.imgur.com") || false;
  const isYtShorts =
    src?.includes("youtube.com/shorts") ||
    src?.includes("i.ytimg.com") ||
    false;

  let _src =
    isGif || isImgur ? src : `https://wsrv.nl/?url=${src}&q=30&output=webp`;

  const [retries, setRetries] = useState(0);

  const imageStyle = {
    maxWidth: "100%",
    alignSelf: "center",
    backgroundColor: "inherit",
    display: "block"
  };

  if (isYtShorts) return null;

  return (
    <span className="flex py-1.5 w-fit">
      <span className="flex flex-col not-prose w-fit h-fit bg-gray-200 dark:bg-zinc-800 bg-repeat border border-pri dark:border-pri-d rounded-xl overflow-hidden">
        <img
          src={_src}
          alt={alt ?? "Image from thread"}
          loading="lazy"
          title={alt ?? "Image from thread"}
          style={imageStyle}
          className="max-h-none sm:max-h-[60vh] object-contain sm:object-cover z-1"
          onError={e => {
            if (retries === 1) {
              e.currentTarget.src = src;
              setRetries(2);
            }
            if (retries === 0) {
              e.currentTarget.src = `https://images.hive.blog/0x0/${src}`;
              setRetries(1);
            }
          }}
        />
      </span>
    </span>
  );
}
