import React from "react";
import CircularProgress from "~/components/CircularProgress";
import classNames from "classnames";
import { getTheme } from "~/utils/theme";

interface CharacterLimitIndicatorProps {
  contentLength: number;
  THREADS_LIMIT: number;
  progressAsPercentage: number;
  remainingLimit: number;
  isDarkMode: boolean;
  settings: any;
}

const CharacterLimitIndicator = ({
  contentLength,
  THREADS_LIMIT,
  progressAsPercentage,
  remainingLimit,
  isDarkMode,
  settings
}: CharacterLimitIndicatorProps) => {
  return (
    <div className="relative flex select-none border-r border-pri/75 dark:border-pri-d/75 pr-3 mr-1">
      <span
        className={classNames("text-xs text-slate-400", {
          "!text-red-400": contentLength > THREADS_LIMIT
        })}
      >
        {/* {content.length} / {THREADS_LIMIT} */}
        <CircularProgress
          size={26}
          progress={progressAsPercentage}
          trackWidth={2}
          indicatorWidth={2}
          trackColor={
            remainingLimit <= 0 ? "#ef4444" : isDarkMode ? "#1f2937" : "#e5e7eb"
          }
          indicatorColor={
            remainingLimit <= 0 ? "#ef4444" : getTheme(settings.color)
          }
          label={remainingLimit <= 20 ? remainingLimit.toString() : false}
          expandOnWarn={remainingLimit <= 20}
        />
      </span>
    </div>
  );
};

export default CharacterLimitIndicator;
