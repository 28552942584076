import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";

import { faAdd, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef, useState } from "react";
import { FloatingPortal } from "@floating-ui/react";
import { createBookmarkFolder } from "~/utils/leocache";
import { toast } from "react-toastify";
import classNames from "classnames";
import { isSSR } from "~/utils/ssr";
import { useAppStore } from "~/store";

interface ModalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
}

export default function CreateFolderModal({
  visibility,
  setVisibility
}: ModalProps) {
  const [{ activeAccount, keys }, isDarkMode] = useAppStore(store => [
    store.account,
    store.settings.dark
  ]);
  const { signature, publicKey, proxy } = keys;
  const [signatureFromStorage] = useState(
    !isSSR() && window.localStorage.getItem("activeAccount")
  );
  // const { mutate } = useContext(BookmarkContext);

  const modalRef = useRef<HTMLDivElement>(null);
  const [folderName, setFolderName] = useState<string>("");

  const [creating, setCreating] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!activeAccount || creating) return;

    if ((!signature && !signatureFromStorage) || !publicKey) {
      return toast("Please sign in with Hive Keychain or HiveSigner.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    if (!folderName) {
      return toast("Please enter a valid folder name.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    setCreating(true);

    try {
      await createBookmarkFolder(
        folderName,
        activeAccount?.name,
        signature || signatureFromStorage,
        publicKey,
        proxy === "hivesigner"
      );

      // await mutate();

      setCreating(false);

      toast("Folder successfully created!", {
        type: "success",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });

      setVisibility(false);
    } catch {
      setCreating(false);

      toast("There is an error occured while creating folder!", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }
  };

  if (!visibility) return null;
  return (
    <FloatingPortal>
      <FloatingHTMLOverlay className="bg-overlay z-[1000] flex justify-center py-[10vh]">
        <div
          ref={modalRef}
          className="animate-reveal-form relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full tbl:min-w-[420px]"
        >
          <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-zinc-700/[.75]">
            <strong className="text-lg">New Folder</strong>
            <div
              onClick={() => setVisibility(false)}
              className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </header>

          <div className="flex flex-col gap-y-5">
            <div className="flex flex-col gap-y-2">
              <label htmlFor="name" className="text-sm font-medium">
                Folder Name
              </label>

              <input
                type="text"
                id="name"
                name="name"
                className="py-3 px-3 rounded-lg border border-pri dark:border-pri-d bg-transparent text-sm focus:border-acc dark:focus:border-acc outline-none transition-colors duration-150"
                placeholder="Enter name"
                value={folderName}
                onChange={event => setFolderName(event.target.value)}
                required
              />
            </div>
          </div>

          <footer className="flex justify-end gap-x-2">
            <button
              className={classNames(
                "py-2 px-4 rounded-full bg-neutral-200 dark:bg-neutral-700 text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150",
                {
                  "opacity-60 cursor-not-allowed": creating
                }
              )}
              onClick={() => setVisibility(false)}
              disabled={creating}
            >
              Cancel
            </button>
            <button
              onClick={() => onSubmit()}
              className={classNames(
                "py-2 px-4 rounded-full bg-acc text-sm font-medium hover:bg-acc-hov transition-all duration-150",
                {
                  "opacity-60 cursor-not-allowed": creating
                }
              )}
              disabled={creating}
            >
              {creating ? (
                <span className="flex items-center gap-x-2">
                  <span>Creating</span>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                </span>
              ) : (
                "Create Folder"
              )}
            </button>
          </footer>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}
