import { useState } from "react";
import Images from "./Images";

interface ImageProps {
  images: string[];
}

const chunk = (arr: string[], size: number) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
};

export default function ImagesGrid({ images }: ImageProps) {
  if (images.length === 0) return null;
  if (images.length === 1) {
    return <Images src={images[0]} alt={""} />;
  }

  return (
    <div className="relative rounded-xl border border-pri dark:border-pri-d overflow-hidden">
      <div className="block overflow-hidden">
        <div className="block w-full pb-[56.25%]" />

        <div className="absolute inset-0 w-full h-full">
          <div className="relative flex flex-col w-full h-full gap-[3px]">
            {/* split array into 2 chunks */}
            {chunk(images, 2)
              .slice(0, 2)
              .map((row, index) => (
                <div
                  key={index}
                  className="relative flex flex-1 flex-row flex-wrap gap-[3px]"
                >
                  {row.map((img, index) => (
                    <Image key={index} src={img} />
                  ))}
                </div>
              ))}

            {/* display +n if images are more than 4 */}
            {images.length > 4 ? (
              <span className="absolute right-0 bottom-0 w-1/2 h-1/2 rounded-br-xl bg-black/80 flex justify-center items-center z-10 pointer-events-none select-none">
                <span className="text-white text-3xl">
                  +{images.length - 4}
                </span>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function Image({ src, alt }: any) {
  const isGif = src?.endsWith?.(".gif") || false;
  const isImgur = src?.startsWith("https://i.imgur.com") || false;
  const isYtShorts = src?.includes("youtube.com/shorts") || false;

  let _src =
    isGif || isImgur ? src : `https://wsrv.nl/?url=${src}&q=50&output=webp`;

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = event => {
    setImageLoaded(true);
  };

  const imageStyle = {
    margin: 0,
    padding: 0,
    backgroundColor: "inherit",
    objectFit: "cover",
    display: "block"
  };

  const [retries, setRetries] = useState(0);

  if (isYtShorts) return null;

  return (
    <div className="relative flex flex-1 p-0 m-0 w-full h-full overflow-hidden">
      <div className="absolute left-0 top-0 w-full h-full">
        <img
          src={_src}
          alt={alt ?? "Image from thread"}
          title={alt ?? "Image from thread"}
          className={`w-full h-full object-center ${
            imageLoaded ? "" : "pulsate"
          } z-1`}
          style={imageStyle}
          onLoad={handleImageLoad}
          onError={e => {
            if (retries === 1) {
              e.target.src = src;
              setRetries(2);
            }
            if (retries === 0) {
              e.target.src = `https://images.hive.blog/0x0/${src}`;
              setRetries(1);
            }
          }}
        />
      </div>
    </div>
  );
}
