export const extractFirstFrameAsFile = videoFile => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const handleLoadedData = () => {
      video.currentTime = 0;
    };

    const handleSeeked = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob], "first_frame.jpg", {
            type: "image/jpeg"
          });
          resolve(file);
        } else {
          reject(new Error("Canvas toBlob conversion failed"));
        }
      }, "image/jpeg");
    };

    video.addEventListener("loadeddata", handleLoadedData);
    video.addEventListener("seeked", handleSeeked);

    video.src = URL.createObjectURL(videoFile);
    video.load();

    // Clean up event listeners once we're done
    video.onended = () => {
      video.removeEventListener("loadeddata", handleLoadedData);
      video.removeEventListener("seeked", handleSeeked);
    };

    // Error handling
    video.onerror = error => {
      reject(new Error("Error loading video"));
    };
  });
};
